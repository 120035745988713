import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    padding: 5,
    paddingVertical: 3,
    borderRadius: 3,
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold',
  },
});

function TextBadge(props) {
  return (
    <View
      style={[
        styles.container,
        { ...props.style },
        { backgroundColor: props.backgroundColor },
      ]}
    >
      <Text
        style={[styles.text, { color: props.color }, { ...props.textStyles }]}
      >
        {props.text}
      </Text>
    </View>
  );
}

export default TextBadge;
