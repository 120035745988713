import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialIcons';
import SwitchCell from '../UI/Cells/SwitchCell';
import { setInvitation } from '../../store/actions/index';
import { getThemeStyles } from '../../settings/UISettings';
import RoundedModal from '../modals/RoundedModal';

const mapDispatchToProps = dispatch => ({
  setInvitation: invitation => dispatch(setInvitation(invitation)),
});

const mapStateToProps = state => ({
  invite_friends: state.ui.invitation.invite_friends,
});

class InviteFriendsSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  toggleInviteFriends = invite_friends =>
    this.props.setInvitation({ invite_friends });

  renderMiddleComponent() {
    const { theme } = this.props;
    const color = theme ? getThemeStyles(theme).color : 'black';
    return (
      <TouchableOpacity
        style={{ flexDirection: 'row', alignItems: 'center' }}
        onPress={() => this.setState({ isModalVisible: true })}
      >
        <Text style={{ fontSize: 17, color, marginRight: 5 }}>
          Freunde einladen erlaubt
        </Text>
        <Icon name={'help-outline'} color={color} size={17} />
        <RoundedModal
          isVisible={this.state.isModalVisible}
          onClose={() => this.setState({ isModalVisible: false })}
        >
          <Text>
            Ist diese Option gewählt, können Teilnehmer ihre Freunde zum Anlass
            einladen.
          </Text>
        </RoundedModal>
      </TouchableOpacity>
    );
  }

  render = () => {
    return (
      <SwitchCell
        MiddleComponent={this.renderMiddleComponent()}
        value={this.props.invite_friends}
        onValueChange={this.toggleInviteFriends}
        {...this.props}
      />
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteFriendsSwitch);
