import * as Sentry from '@sentry/react-native';
import { normalize } from 'normalizr';
import AsyncStorage from '../../../storage';
import {
  REQUEST_EVENTS,
  RECEIVE_EVENTS,
  INVALIDATE_EVENTS,
} from './actionTypes';
import { host } from '../../settings/environment';
import { startLoading, stopLoading } from './index';
import { updateEntities } from './entities';
import schemata from '../schemata';

function getAuthToken() {
  return AsyncStorage.get('triff:auth:token')
    .catch(err => {
      Sentry.captureException(err);
      Promise.reject(err);
    })
    .then(token => token);
}

export function invalidateEvents(eventType) {
  return {
    type: INVALIDATE_EVENTS,
    eventType,
  };
}

export function requestEvents(eventType) {
  return {
    type: REQUEST_EVENTS,
    eventType,
  };
}

export function receiveEvents(eventType, data, refresh = false) {
  if (eventType === 'last') {
    data = [data];
  }
  return {
    type: RECEIVE_EVENTS,
    eventType,
    events: data.results || data, // Use data.results if paginated.
    receivedAt: Date.now(),
    nextUrl: data.next,
    totalItems: data.count,
    refresh,
  };
}

function shouldFetchEvents(eventsByType) {
  const { didInvalidate, items, totalItems, isFetching } = eventsByType;
  if (isFetching) {
    return false;
  }
  if (didInvalidate) {
    return true;
  }
  if (!items) {
    return true;
  }
  if (totalItems > items.length) {
    return true; // Get more paginated items.
  }
  if (!totalItems && items.length > 0) {
    return false; // All unpaginated items have been fetched.
  }
  return false;
}

function getSchema(type) {
  if (type.match(/templates|hosted/)) {
    return [schemata.template];
  }
  if (type === 'last') {
    return schemata.template;
  }
  return [schemata.event];
}

export function fetchEvents(type, refresh = false, customPageStart = null) {
  return async (dispatch, getState) => {
    const eventsByType = getState().ui.events[type];
    if (!refresh && !shouldFetchEvents(eventsByType)) {
      return Promise.resolve();
    }
    let url = refresh
      ? `${host}/api/events/${type}/` // Use the unpaginated endpoint if events should be refreshed
      : eventsByType.nextUrl || `${host}/api/events/${type}/`;
    // Construct the query URL from the saved events in the Redux store:
    if (customPageStart) {
      url = `${host}/api/events/${type}/?limit=10&offset=${customPageStart}`;
    }
    dispatch(requestEvents(type));
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${await getAuthToken()}`,
      },
    })
      .then(
        response => {
          if (!response.ok) {
            throw new Error(response.status);
          }
          response.json().then(data => {
            if (type.match(/templates|hosted/) && data.results.length > 0) {
              // Use a proxy as ID when normalizing because the ID is not sent from the server.
              data = {
                ...data,
                results: data.results.map(template => ({
                  ...template,
                  idProxy:
                    type === 'templates' ? template.text[0] : template.created,
                })),
              };
            }
            if (type === 'last' && data.created) {
              data = {
                ...data,
                idProxy: data.created,
              };
            }
            const normalizedData = normalize(
              data.results || data,
              getSchema(type),
            );
            if (normalizedData.result) {
              dispatch(updateEntities(normalizedData));
            }
            return dispatch(receiveEvents(type, data, refresh));
          });
        },
        error => {
          throw new Error(error);
        },
      )
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  };
}

export function refreshEvents(type) {
  return dispatch => {
    return dispatch(fetchEvents(type, true));
  };
}

export function refreshAllEvents() {
  return async dispatch => {
    const eventTypesByScreen = {
      addEvent: ['hosted', 'templates'],
      calendar: ['user', 'past', 'last'],
    };
    Object.keys(eventTypesByScreen).forEach(async screen => {
      dispatch(startLoading(screen));
      await Promise.all(
        eventTypesByScreen[screen].map(type => dispatch(refreshEvents(type))),
      );
      dispatch(stopLoading(screen));
    });
    return true;
  };
}
