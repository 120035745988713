import React from 'react';
import { SafeAreaView, Text, StyleSheet } from 'react-native';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  networkData: state.ui.network,
});

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'red',
    width: '100%',
    padding: 10,
  },
  text: {
    color: 'white',
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold',
  },
});

function NoNetworkHeader(props) {
  const { networkData } = props;
  if (!networkData) {
    return null;
  }
  const noConnection = networkData.type === 'none';
  if (noConnection) {
    return (
      <SafeAreaView style={styles.container}>
        <Text style={styles.text}>Keine Internetverbindung</Text>
      </SafeAreaView>
    );
  }
  return null;
}

export default connect(mapStateToProps)(NoNetworkHeader);
