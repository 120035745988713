import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TouchableHighlight,
  Platform,
  KeyboardAvoidingView,
  StatusBar,
} from 'react-native';
import { SafeAreaView } from 'react-navigation';
import Modal from 'react-native-modal';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Brandcolor, HITSLOPS } from '../../settings/UISettings';

const styles = StyleSheet.create({
  header: {
    padding: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    borderBottomColor: '#00000020',
    borderBottomWidth: 0.5,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  closeButton: {
    // position: 'absolute',
    flex: 0,
    top: 15,
    left: 15,
    zIndex: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 20,
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const BottomModal = props => (
  <Modal
    style={{
      justifyContent: 'flex-end',
      margin: 0,
    }}
    isVisible={props.isVisible}
    onBackdropPress={props.onClose}
    hideModalContentWhileAnimating
    useNativeDriver
    avoidKeyboard={Platform.OS === 'ios'}
  >
    <KeyboardAvoidingView
      behavior={Platform.OS === 'android' ? 'padding' : null}
      enabled
    >
      <SafeAreaView
        style={{
          backgroundColor: 'white',
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
        }}
        forceInset={{ bottom: 'always', top: 'never' }}
      >
        <View style={styles.header}>
          <TouchableOpacity
            onPress={props.onClose}
            hitSlop={HITSLOPS.fifteen}
            style={{ flex: 1 }}
          >
            <Icon name={'keyboard-arrow-down'} color={Brandcolor} size={24} />
          </TouchableOpacity>
          <Text style={styles.title}>{props.title}</Text>
          <TouchableOpacity
            onPress={props.headerRightAction || props.onClose}
            hitSlop={HITSLOPS.fifteen}
            style={{ flex: 1, alignItems: 'flex-end' }}
          >
            {/* <Text style={{ color: Brandcolor, fontSize: 18 }}>
              {props.headerRight || 'Schliessen'}
            </Text> */}
          </TouchableOpacity>
        </View>
        {props.children}
      </SafeAreaView>
    </KeyboardAvoidingView>
  </Modal>
);

export const FullScreenModal = props => (
  <Modal
    {...props}
    style={{ margin: 0 }}
    isVisible={props.isVisible}
    hideModalContentWhileAnimating
    useNativeDriver
    avoidKeyboard
  >
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: props.backgroundColor || 'white',
      }}
      forceInset={{ top: 'always' }}
    >
      <StatusBar hidden={!props.isVisible} />
      <TouchableHighlight
        onPress={props.onClose}
        style={styles.closeButton}
        hitSlop={HITSLOPS.fifteen}
      >
        <Icon size={32} name={props.topLeftIcon || 'close'} color={'white'} />
      </TouchableHighlight>
      {props.children}
    </SafeAreaView>
  </Modal>
);

export const FullScreenHeaderModal = props => (
  <Modal
    {...props}
    style={{ margin: 0 }}
    isVisible={props.isVisible}
    hideModalContentWhileAnimating
    useNativeDriver
    avoidKeyboard
  >
    <StatusBar hidden={!props.isVisible} />
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: 'white',
      }}
      forceInset={{ top: 'always', bottom: 'always' }}
    >
      <View style={styles.header}>
        <TouchableOpacity
          onPress={props.onClose}
          hitSlop={HITSLOPS.fifteen}
          style={{ flex: 1 }}
        >
          <Icon name={'close'} color={Brandcolor} size={30} />
        </TouchableOpacity>
        <View style={{ flex: 0, alignItems: 'center' }}>
          <Text style={styles.title}>{props.title}</Text>
        </View>
        <TouchableOpacity
          onPress={props.headerRightAction || props.onClose}
          hitSlop={HITSLOPS.fifteen}
          style={{ flex: 1, alignItems: 'flex-end' }}
        >
          <View style={{ minWidth: 24 }}>{props.headerRightComponent}</View>
        </TouchableOpacity>
      </View>
      {props.children}
    </SafeAreaView>
  </Modal>
);

export { default as FullScreenModalWithBottomAction } from '../modals/FullScreenModalWithBottomAction';
