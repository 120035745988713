import React from 'react';
import { connect } from 'react-redux';
import SwitchCell from '../UI/Cells/SwitchCell';
import { setInvitation } from '../../store/actions/index';

const mapDispatchToProps = dispatch => ({
  setInvitation: invitation => dispatch(setInvitation(invitation)),
});

const mapStateToProps = state => ({
  visibility: state.ui.invitation.visibility,
});

class PublicSwitch extends React.Component {
  toggleVisibility = () => {
    if (this.props.visibility === 'public') {
      this.props.setInvitation({ visibility: null });
    } else {
      this.props.setInvitation({ visibility: 'public' });
    }
  };

  render = () => {
    return (
      <SwitchCell
        title={'Öffentlicher Triff'}
        value={this.props.visibility === 'public'}
        onValueChange={this.toggleVisibility}
      />
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicSwitch);
