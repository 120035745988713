import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Platform,
} from 'react-native';
import { clamp } from 'lodash/number';
import { connect } from 'react-redux';
import * as Animatable from 'react-native-animatable';
import dayjs from 'dayjs';
import Color from 'color';
import Hypher from 'hypher';
import german from 'hyphenation.de';
import FacePile from '../UI/FacePile';
import { getThemeStyles } from '../../settings/UISettings';
import { SHADOWS } from '../../settings/shadows';
import { COLORS } from '../../settings/colors';
import InvitationBackground from './InvitationBackground';
import { Icon } from '../../assets/TriffIcons';
import { CALENDAR, getLocale } from '../../utilities/i18n';
import Badge from '../UI/Badge';
import { IconButton } from '../index';
import TextBadge from '../badges/TextBadge';
import { selectConfirmedEventParticipations } from '../../store/selectors/event';
import DefaultAvatar from '../../assets/images/avatars/default_avatar.png';
import { isRowLayout } from '../../utilities/screen';

const hyphenator = new Hypher(german);

const mapStateToProps = (state, ownProps) => ({
  userID: state.account.id,
  notifications: state.ui.notifications,
  placesByID: state.entities.places.byID || [],
  usersByID: state.entities.users.byID || [],
  isPublicSite: Platform.OS === 'web' && !state.auth.token,
  confirmedParticipations: ownProps.isPublicSite
    ? ownProps.event.participations.filter(e => e.status === 'confirmed')
    : ownProps.type !== 'template' &&
      selectConfirmedEventParticipations(state, {
        eventID: ownProps.event.id,
      }),
});

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    overflow: 'visible',
    padding: 5,
    maxHeight: 500,
    maxWidth: '100%',
  },
  shadow: {
    overflow: 'visible',
    ...SHADOWS.smallShadow,
    backgroundColor: 'white',
    borderRadius: 5,
    flex: 1,
  },
  card: {
    borderRadius: 5,
    overflow: 'hidden',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  connectionContainer: {
    flex: 1,
    flexDirection: 'row',
    padding: 15,
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: 45,
    backgroundColor: 'rgba(0,0,0,0.05)',
  },
  connectionText: {
    fontSize: 12,
    lineHeight: 15,
    color: 'rgba(0,0,0,0.7)',
  },
  titleContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  badgeContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    elevation: 10,
  },
  image: {
    width: '100%',
  },
  bar: {
    // flex: 0,
    flexBasis: Platform.OS === 'web' ? 'auto' : 0,
    flexDirection: 'row',
    padding: 10,
  },
  left: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  right: {
    flex: 0,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  title: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  icons: {
    marginLeft: 3,
  },
  facePileOverflowStyle: {
    backgroundColor: 'white',
  },
  facePileOverflowLabelStyle: {
    color: 'black',
  },
  facePileBorder: {
    borderWidth: 1,
  },
  facePileNoBorder: {
    borderWidth: 0,
  },
});

const windowWidth = Dimensions.get('window').width;
const maxWidth = 500;

const typeStyles = {
  full: StyleSheet.create({
    container: {
      width: windowWidth,
      height: windowWidth * 1.15,
      paddingHorizontal: 10,
    },
    title: {
      padding: 20,
    },
  }),
  map: StyleSheet.create({
    container: {
      width: isRowLayout ? '100%' : windowWidth - 60,
      height: (windowWidth - 60) / 1.5,
      paddingVertical: 10,
      maxHeight: maxWidth / 1.5,
    },
    shadow: {
      ...SHADOWS.smallShadow,
    },
    title: {
      // padding: 10,
    },
  }),
  template: StyleSheet.create({
    container: {
      width: windowWidth / 1.75,
      height: (windowWidth / 1.75) * 1.25,
      padding: 5,
      maxWidth,
      maxHeight: maxWidth / 1.25,
    },
    shadow: {
      ...SHADOWS.smallShadow,
    },
    title: {
      padding: 10,
    },
  }),
  profile: StyleSheet.create({
    container: {
      width: windowWidth,
      height: 300,
      paddingHorizontal: 15,
      paddingVertical: 15,
    },
    shadow: {
      ...SHADOWS.smallShadow,
    },
    title: {
      padding: 10,
    },
  }),
};

class EventCard extends React.PureComponent {
  themeStyles = getThemeStyles(this.props.event.theme);

  locale = getLocale().slice(0, 2);

  onPress = () => {
    const { navigation, event, userID, isPublicSite } = this.props;
    if (isPublicSite) return;
    const nextScreen = isRowLayout
      ? 'Event'
      : `Event${navigation.state.routeName}`;
    navigation.navigate(nextScreen, {
      id: event.id,
      eventID: event.id,
      theme: event.theme,
      user_id: userID,
    });
  };

  getFontSize() {
    const { text } = this.props.event;
    const { type } = this.props;
    const defaults = {
      full: {
        length: 30,
        lower: 0.9,
        upper: 1.5,
      },
      profile: {
        length: 20,
        lower: 0.9,
        upper: 1.2,
      },
      map: {
        length: 25,
        lower: 0.6,
        upper: 1,
      },
      template: {
        length: 20,
        lower: 0.7,
        upper: 1.1,
      },
    };
    const factor = defaults[type].length / text[0].length;
    const { lower, upper } = defaults[type];
    const clampedFactor = clamp(factor, lower, upper);
    const { fontSizeFactor } = this.themeStyles;
    const fontSize = 30 * fontSizeFactor;
    return fontSize * clampedFactor;
  }

  renderTitle() {
    const { text } = this.props.event;
    const { type } = this.props;
    const typeStyle = typeStyles[type];
    const title = text[0].trim();
    const { color, fontWeight, fontFamily, shadow } = getThemeStyles(
      this.props.event.theme,
    );
    return (
      <View style={[styles.titleContainer, typeStyle.title]}>
        <Text
          adjustsFontSizeToFit
          allowFontScaling={false}
          ellipsizeMode={'tail'}
          numberOfLines={type === 'map' ? 2 : 4}
          style={[
            styles.title,
            {
              fontSize: this.getFontSize(),
              lineHeight: this.getFontSize() * 1.2,
              color,
              fontWeight,
              fontFamily,
              ...shadow,
              padding: 10,
            },
          ]}
        >
          {hyphenator.hyphenateText(title, 5)}
        </Text>
      </View>
    );
  }

  // renderConnection() {
  //   let content = null;
  //   const { common_friends, distance, created_by } = this.props.event;
  //   if (this.props.userID === created_by.id) return;
  //   const friends = this.props.user.friends.map(friend => friend.id);
  //   if (friends.includes(created_by.id)) return;
  //   if (common_friends && common_friends.length > 0) {
  //     content = this.renderCommonFriends();
  //   }
  //   if (distance) {
  //     content = this.renderDistance();
  //   }
  //   if (content) {
  //     return <View style={styles.connectionContainer}>{content}</View>;
  //   }
  // }

  // renderDistance() {
  //   let distance = this.props.event.distance.toFixed(1);
  //   let unit = 'km';
  //   if (distance < 1) {
  //     distance *= 1000;
  //     unit = 'm';
  //   }
  //   return (
  //     <View style={{ flex: 1, flexDirection: 'row' }}>
  //       <Icon
  //         name={'my_location'}
  //         size={15}
  //         color={this.getThemeStyles('smallText').color}
  //         style={[styles.icons, { marginRight: 10 }]}
  //       />
  //       <Text style={this.getThemeStyles('smallText')}>
  //         {`${distance} ${unit} von deinem Standort`}
  //       </Text>
  //     </View>
  //   );
  // }

  // renderCommonFriends() {
  //   const { common_friends, hosts } = this.props.event;
  //   if (!hosts.find(host => host.id === this.props.userID)) {
  //     let faces = common_friends.map((friend, index) => ({
  //       id: index,
  //       imageUrl:
  //         friend.image ||
  //         DefaultAvatar,
  //     }));
  //     return (
  //       <View style={{ flex: 1 }}>
  //         <View style={{ marginRight: 15 }}>
  //           <FacePile
  //             numFaces={3}
  //             faces={faces}
  //             offset={0.33}
  //             circleSize={10}
  //             overflowStyle={styles.facePileOverflowStyle}
  //             overflowLabelStyle={styles.facePileOverflowLabelStyle}
  //             imageStyle={styles.facePileNoBorder}
  //           />
  //         </View>
  //         <View style={{ flex: 1 }}>
  //           <Text style={styles.connectionText}>
  //             {common_friends.length > 1
  //               ? `Du und ${hosts[0].first_name} habt ${common_friends.length} gemeinsame Freunde.`
  //               : `Du und ${hosts[0].first_name} habt eine/n gemeinsame/n Freund/in.`}
  //           </Text>
  //         </View>
  //       </View>
  //     );
  //   }
  // }

  renderBadge(eventID) {
    const { events, participations } = this.props.notifications;
    const unreadMessages = events.filter(
      item => item.content_object.id === eventID,
    );
    const unreadParticipations = participations.filter(
      item => item.content_object.event === eventID,
    );
    const total = unreadMessages.length + unreadParticipations.length;
    if (total > 0) {
      return (
        <View style={styles.badgeContainer}>
          <Badge size={30} number={total} />
        </View>
      );
    }
    return null;
  }

  getHostAvatars = () => {
    const { usersByID } = this.props;
    const { hosts } = this.props.event;
    return hosts.map(
      host =>
        host.image ||
        (usersByID[host] && usersByID[host].image) ||
        DefaultAvatar,
    );
  };

  getParticipantAvatars() {
    const { confirmedParticipations } = this.props;
    // Get images:
    const avatars = confirmedParticipations.reduce((array, participation) => {
      // Add real images to the beginning of the array. This way,
      // the FacePile shows real images first and default avatars later.
      const user =
        this.props.usersByID[participation.user] || participation.user;
      if (user.image) {
        array.unshift(user.image);
      } else {
        array.push(DefaultAvatar);
      }
      // Add dummy images for entourage:
      if (participation.grown_ups > 1) {
        for (let i = 1; i < participation.grown_ups; i += 1) {
          array.push(DefaultAvatar);
        }
      }
      if (participation.kids > 0) {
        for (let i = 0; i < participation.kids; i += 1) {
          array.push(DefaultAvatar);
        }
      }
      return array;
    }, []);
    return avatars;
  }

  renderFacePile(type) {
    let avatars;
    if (type === 'hosts') {
      avatars = this.getHostAvatars();
    } else {
      avatars = this.getParticipantAvatars();
    }
    const faces = avatars.map(avatar => ({
      imageUrl: avatar,
    }));
    return (
      <View>
        <FacePile
          numFaces={4}
          faces={faces}
          circleSize={15}
          offset={0.33}
          overflowStyle={styles.facePileOverflowStyle}
          overflowLabelStyle={styles.facePileOverflowLabelStyle}
          imageStyle={
            this.props.event.theme === 'image'
              ? styles.facePileBorder
              : styles.facePileNoBorder
          }
        />
      </View>
    );
  }

  renderTop() {
    const { usersByID } = this.props;
    const { hosts } = this.props.event;
    const hostNames = hosts.map(host =>
      usersByID[host] ? usersByID[host].first_name : host.first_name || '',
    );
    return (
      <View style={styles.bar}>
        <View style={styles.left}>
          {this.renderFacePile('hosts')}
          <Text
            style={{
              marginLeft: 15,
              fontSize: 17,
              color: this.themeStyles.color,
            }}
          >
            {hostNames.join(', ').substring(0, 10)}
          </Text>
        </View>
        <View style={styles.right}>{this.renderFacePile('participants')}</View>
      </View>
    );
  }

  _getContributionType = () => {
    const { contribution_type, currency, price } = this.props.event;
    switch (contribution_type) {
      case 'price':
        return (
          <Text style={{ color: this.themeStyles.color }}>
            {`${currency} ${price}`}
          </Text>
        );
      case 'give_what_you_want':
        return (
          <Icon
            name={'piggy_bank'}
            size={24}
            color={this.themeStyles.iconColor}
            style={styles.icons}
          />
        );
      case 'share_expenses':
        return (
          <Icon
            name={'split_costs'}
            size={24}
            color={this.themeStyles.iconColor}
            style={styles.icons}
          />
        );
      default:
        return null;
    }
  };

  getPlaceString() {
    const { event, placesByID } = this.props;
    const place = placesByID[event.place] || event.place;
    if (place) {
      const { address } = place.properties;
      const lastComma = address.lastIndexOf(',');
      const lastSpaceBeforeLastComma = address.lastIndexOf(' ', lastComma);
      const city = place.properties.address.slice(
        lastSpaceBeforeLastComma + 1,
        lastComma,
      );
      return ` · ${city}`;
    }
    return '';
  }

  renderStatus() {
    const { event, userID } = this.props;
    const { participations } = event;
    const userDismissed = participations.find(
      p => p.status === 'dismissed' && p.userID === userID,
    );
    if (event.is_deleted) {
      return (
        <View style={{ backgroundColor: 'red', borderRadius: 3, padding: 5 }}>
          <Text style={{ color: 'white', fontWeight: 'bold' }}>Abgesagt</Text>
        </View>
      );
    }
    if (userDismissed) {
      return (
        <IconButton
          style={{
            backgroundColor: COLORS.salmon,
          }}
          size={30}
          name={'thumb-down'}
        />
      );
    }
    const isPublic = event.visibility === 'public';
    return (
      <TextBadge
        text={isPublic ? 'ÖFFENTLICH' : 'PRIVAT'}
        backgroundColor={Color(this.themeStyles.color).alpha(0.8)}
        color={this.themeStyles.backgroundGradient[1]}
      />
    );
  }

  renderBottom() {
    const { starts_at } = this.props.event;
    const { type } = this.props;
    return (
      <View style={styles.bar}>
        <View style={styles.left}>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            {type !== 'map' && (
              <Text
                style={[
                  this.themeStyles.text,
                  {
                    fontSize: 17,
                    fontWeight: 'bold',
                    color: this.themeStyles.color,
                  },
                ]}
              >
                {dayjs(starts_at).calendar(
                  null,
                  CALENDAR.withoutTime[this.locale],
                )}
              </Text>
            )}
            <Text
              ellipsizeMode={'tail'}
              numberOfLines={1}
              style={[
                this.themeStyles.text,
                {
                  fontSize: 13,
                  fontWeight: '400',
                  color: this.themeStyles.color,
                },
              ]}
            >
              {type !== 'map'
                ? `${dayjs(starts_at).format('H:mm')}${this.getPlaceString()}`
                : dayjs(starts_at).calendar(
                    null,
                    CALENDAR.withTime[this.locale],
                  )}
            </Text>
          </View>
        </View>
        <View style={styles.right}>
          {/* {this._getContributionType()} */}
          {this.renderStatus()}
        </View>
      </View>
    );
  }

  render = () => {
    const { type, showBadge, event, onPress } = this.props;
    const { theme, image, id } = event;
    const typeStyle = typeStyles[type];
    const showDetails = type.match(/full|map|profile/);
    const Wrapper = type === 'full' ? Animatable.View : View;
    return (
      <Wrapper useNativeDriver={Platform.OS !== 'web'} animation={'fadeIn'}>
        <TouchableOpacity
          onPress={onPress ? () => onPress(event) : this.onPress}
          key={id}
        >
          <View
            style={[
              styles.container,
              typeStyle.container,
              this.props.width && { width: this.props.width },
              this.props.height && { height: this.props.height },
            ]}
          >
            <View style={[styles.shadow, typeStyle.shadow]}>
              <View style={styles.card}>
                <View style={StyleSheet.absoluteFill}>
                  <InvitationBackground
                    theme={theme}
                    source={image}
                    type={type}
                  />
                  <View style={StyleSheet.absoluteFill}>
                    {showDetails && this.renderTop()}
                    {this.renderTitle()}
                    {showDetails && this.renderBottom()}
                  </View>
                </View>
                {/* {this.renderConnection()} */}
              </View>
            </View>
            {showBadge && this.renderBadge(id)}
          </View>
        </TouchableOpacity>
      </Wrapper>
    );
  };
}

EventCard.defaultProps = {
  type: 'full',
};

export default connect(mapStateToProps)(EventCard);
