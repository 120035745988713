import union from 'lodash/union';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import {
  UPDATE_ENTITIES,
  UPDATE_IDS,
  ADD_MESSAGE,
  DELETE_MESSAGE,
  DELETE_LOCAL_MEDIA,
} from '../../actions/actionTypes';

function deleteLocalMedia(state = {}, action) {
  return {
    ...state,
    [action.uuid]: {
      ...state[action.uuid],
      localImage: {},
      localVideo: {},
    },
  };
}

function addMessage(state = {}, action) {
  return {
    ...state,
    [action.message.uuid]: action.message,
  };
}

function deleteMessage(state, action) {
  return omit(state, action.message.uuid);
}

function messagesByID(state = {}, action) {
  switch (action.type) {
    default:
      if (action.entities && action.entities.messages) {
        return merge({}, state, action.entities.messages);
      }
      return state;
  }
}

function allMessageIDs(state = [], action) {
  switch (action.type) {
    default:
      if (action.entity === 'messages') {
        return union(state, action.result);
      }
      return state;
  }
}

export default function messages(
  state = {
    byID: {},
    allIDs: [],
  },
  action,
) {
  switch (action.type) {
    case UPDATE_ENTITIES:
      return {
        ...state,
        byID: messagesByID(state.byID, action),
      };
    case UPDATE_IDS:
      return {
        ...state,
        allIDs: allMessageIDs(state.allIDs, action),
      };
    case ADD_MESSAGE:
      return {
        ...state,
        byID: addMessage(state.byID, action),
      };
    case DELETE_MESSAGE:
      return {
        ...state,
        byID: deleteMessage(state.byID, action),
      };
    case DELETE_LOCAL_MEDIA:
      return {
        ...state,
        byID: deleteLocalMedia(state.byID, action),
      };
    default:
      return state;
  }
}
