import React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import {
  Animated,
  View,
  StyleSheet,
  ImageBackground,
  Platform,
  findNodeHandle,
} from 'react-native';
import { getThemeStyles, noise } from '../../settings/UISettings';
import { COLORS } from '../../settings/colors';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  imageBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  imageStyle: {
    resizeMode: 'repeat',
    borderRadius: 15,
  },
});

export default class InvitationBackground extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { imageOpacity: new Animated.Value(0) };
  }

  imageLoaded = () => {
    Animated.timing(this.state.imageOpacity, {
      toValue: 1,
      useNativeDriver: true,
    }).start();
  };

  getBackgroundColor() {
    if (this.props.blurred) {
      return 'rgba(0,0,0,0.4)';
    }
    if (this.props.scrollY) {
      return this.props.scrollY.interpolate({
        inputRange: [0, 150],
        outputRange: ['rgba(0,0,0,0.1)', 'rgba(0,0,0,0.4)'],
        extrapolate: 'clamp',
        useNativeDriver: true,
      });
    }
    return 'transparent';
  }

  getOpacity() {
    const { blurred, scrollY } = this.props;
    if (blurred) {
      return 1;
    }
    if (scrollY) {
      return scrollY.interpolate({
        inputRange: [0, 100],
        outputRange: Platform.OS === 'web' ? [0.75, 0.25] : [0, 1],
        extrapolate: 'clamp',
        useNativeDriver: true,
      });
    }
    return 0;
  }

  getBlurAmount() {
    const { blurred, scrollY } = this.props;
    if (blurred || scrollY) {
      return 25;
    }
    if (Platform.OS === 'ios') {
      return 0;
    }
    return 1;
  }

  renderImage() {
    return (
      <Animated.Image
        useNativeDriver={Platform.OS !== 'web'}
        source={{ uri: this.props.source }}
        defaultSource={require('../../assets/dark-placeholder.png')}
        style={[
          styles.imageBackground,
          {
            overflow: 'hidden',
            backgroundColor: COLORS.black,
            opacity:
              Platform.OS === 'web'
                ? this.getOpacity()
                : this.state.imageOpacity,
          },
        ]}
        imageStyle={{ resizeMode: 'cover' }}
        onLoad={this.imageLoaded}
        ref={img => {
          this.backgroundImage = img;
        }}
      />
    );
  }

  renderChildren() {
    return (
      <Animated.View
        useNativeDriver={Platform.OS !== 'web'}
        style={{
          flex: 1,
          opacity:
            this.props.scrollY &&
            this.props.scrollY.interpolate({
              inputRange: [-100, 0],
              outputRange: [0, 1],
              extrapolate: 'clamp',
            }),
        }}
      >
        {this.props.children}
      </Animated.View>
    );
  }

  render = () => {
    const themeStyles = getThemeStyles(this.props.theme);
    if (this.props.theme === 'image' && this.props.source) {
      return (
        <View style={styles.container}>
          {this.renderImage()}
          {this.renderChildren()}
        </View>
      );
    }
    return (
      <LinearGradient
        colors={themeStyles.backgroundGradient}
        style={[styles.container, themeStyles.background]}
      >
        <ImageBackground
          source={Platform.OS === 'ios' ? noise : null}
          style={[styles.imageBackground, { justifyContent: 'space-around' }]}
          imageStyle={styles.imageStyle}
        >
          {this.props.children}
        </ImageBackground>
      </LinearGradient>
    );
  };
}
