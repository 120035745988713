import AsyncStorage from '../../../storage';
import {
  AUTH_SET_TOKEN,
  AUTH_REMOVE_TOKEN,
  AUTH_CONFIRM_CODE,
} from './actionTypes';
import { updateUser } from './account';
import { host } from '../../settings/environment';

export const authConfirmCode = () => {
  return {
    type: AUTH_CONFIRM_CODE,
  };
};

export const authSetToken = userData => {
  return {
    type: AUTH_SET_TOKEN,
    ...userData,
  };
};

export const authStoreToken = userData => {
  return dispatch => {
    dispatch(authSetToken(userData));
    AsyncStorage.set('triff:auth:token', userData.token);
    AsyncStorage.set('triff:auth:id', userData.id.toString());
  };
};

export const authClearStorage = () => {
  AsyncStorage.removeItem('triff:auth:token');
};

export const authRemoveToken = () => {
  return {
    type: AUTH_REMOVE_TOKEN,
  };
};

export const authLogout = () => {
  return dispatch => {
    dispatch(authClearStorage());
    dispatch(authRemoveToken());
  };
};

const checkResponse = async response => {
  if (!response.ok) {
    const error = await response.json();
    throw new Error(error);
  }
  return response.json();
};

export const getUserData = user => async dispatch => {
  await dispatch(updateUser(user.token));
  await dispatch(authStoreToken(user));
  return true;
};

const catchErrors = e => {
  console.log(e);
  return false;
};

export const requestVerificationCode = (identifier, action) => dispatch => {
  const body = identifier.includes('@')
    ? { email: identifier, action }
    : { phone: identifier, action };
  return fetch(`${host}/api/verification-code/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  }).then(checkResponse);
};

export const verifyCode = ({ identifier, code }) => dispatch => {
  const type = identifier.includes('@') ? 'email' : 'phone';
  return fetch(`${host}/api/verify-code/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      code,
      identifier,
      type,
    }),
  })
    .then(checkResponse)
    .then(userData => dispatch(getUserData(userData)))
    .catch(catchErrors);
};

export const registerWeb = ({
  firstName,
  lastName,
  gender,
  birthYear,
  password,
  email,
  code,
}) => dispatch => {
  const createPassword = () => Math.floor(Math.random() * 1000000 + 1);
  return fetch(`${host}/api/web/register/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      gender,
      birth_year: birthYear,
      password: password || createPassword(),
      is_dummy: false,
      email,
      code,
    }),
  })
    .then(checkResponse)
    .then(user => dispatch(getUserData(user)))
    .catch(catchErrors);
};
