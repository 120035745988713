import React from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { getDuration } from '../../utilities/time';
import Cell from '../UI/Cells/Cell';
import { setInvitation } from '../../store/actions/index';
import { capitalizeFirstLetter } from '../../utilities/strings';
import { CALENDAR, getLocale } from '../../utilities/i18n';
import FullScreenModalWithBottomAction from '../modals/FullScreenModalWithBottomAction';
import BoldCellValue from '../UI/Cells/BoldCellValue';

const mapDispatchToProps = dispatch => ({
  setInvitation: invitation => dispatch(setInvitation(invitation)),
});

const mapStateToProps = state => ({
  starts_at: state.ui.invitation.starts_at,
  ends_at: state.ui.invitation.ends_at,
});

class StartTimePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      DatePickerComponent: null,
    };
  }

  async componentDidMount() {
    const DatePickerComponent = await import(
      '../../components/pickers/DateTimePickerWeb'
    );
    this.setState({ DatePickerComponent: DatePickerComponent.default });
  }

  locale = getLocale().slice(0, 2);

  getValue = () => {
    const { starts_at, theme } = this.props;
    const date = dayjs(starts_at).calendar(
      null,
      CALENDAR.withTime[this.locale],
    );
    return <BoldCellValue theme={theme} text={capitalizeFirstLetter(date)} />;
  };

  onDateChange = starts_at => {
    if (this.props.ends_at) {
      const duration = getDuration(
        this.props.starts_at,
        new Date(this.props.ends_at),
      );
      const ends_at = new Date(starts_at.valueOf() + duration);
      this.props.setInvitation({ ends_at });
    }
    this.props.setInvitation({ starts_at });
  };

  renderExpansion = () => {
    const { DatePickerComponent } = this.state;
    if (!DatePickerComponent) {
      return null;
    }
    return (
      <FullScreenModalWithBottomAction
        onClose={() => this.props.toggleCell('starts_at')}
        isVisible={this.props.isExpanded}
        title={'Beginn'}
      >
        <View style={{ alignItems: 'center' }}>
          <DatePickerComponent
            selected={new Date(this.props.starts_at)}
            onChange={this.onDateChange}
            minDate={new Date()}
            locale={this.locale}
          />
        </View>
      </FullScreenModalWithBottomAction>
    );
  };

  render = () => {
    return (
      <Cell
        title={'Beginn'}
        RightComponent={this.getValue()}
        ExpansionComponent={this.renderExpansion()}
        {...this.props}
        onPress={() => {
          if (!this.props.isExpanded) {
            this.props.toggleCell('starts_at');
          }
        }}
      />
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StartTimePicker);
