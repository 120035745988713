import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';

export const getGroupsByID = state => state.entities.groups.byID;

export const selectGroups = createSelector(getGroupsByID, groups =>
  Object.values(groups).filter(g => !g.adhoc),
);

export const selectAdhocGroups = createSelector(getGroupsByID, groups =>
  Object.values(groups).filter(g => g.adhoc),
);

export const selectUniqueAdhocGroups = createSelector(
  selectAdhocGroups,
  adHocGroups =>
    uniqWith(adHocGroups, (g1, g2) => isEqual(g1.members, g2.members)),
);
