import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import Modal from 'react-native-modal';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'stretch',
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    margin: 'auto',
  },
});

const RoundedModal = props => (
  <Modal
    {...props}
    hideModalContentWhileAnimating
    useNativeDriver={Platform.OS !== 'web'}
    avoidKeyboard
    isVisible={props.isVisible}
    onBackdropPress={props.onClose}
  >
    <View style={styles.container}>{props.children}</View>
  </Modal>
);

export default RoundedModal;
