import React from 'react';
import { Text, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  h1: {
    fontSize: 'xxx-large',
    fontWeight: 'bold',
  },
  h2: {
    fontSize: 'xx-large',
    fontWeight: 'bold',
  },
  lead: {
    fontSize: 20,
    fontWeight: '500',
    lineHeight: 20 * 1.25,
  },
  p: {
    fontSize: 17,
    lineHeight: 16 * 1.25,
  },
});

export function H1(props) {
  return (
    <Text
      style={[
        props.style,
        styles.h1,
        {
          textAlign: props.centered ? 'center' : null,
        },
      ]}
      accessibilityRole="heading"
    >
      {props.children}
    </Text>
  );
}

export function H2(props) {
  return (
    <Text
      style={[
        styles.h2,
        props.style,
        {
          textAlign: props.centered ? 'center' : null,
        },
      ]}
      accessibilityRole="heading"
      aria-level="2"
    >
      {props.children}
    </Text>
  );
}

export function Lead(props) {
  return (
    <Text
      style={[
        props.style,
        styles.lead,
        {
          textAlign: props.centered ? 'center' : null,
        },
      ]}
    >
      {props.children}
    </Text>
  );
}

export function P(props) {
  return (
    <Text
      style={[
        props.style,
        styles.p,
        {
          textAlign: props.centered ? 'center' : null,
        },
      ]}
    >
      {props.children}
    </Text>
  );
}
