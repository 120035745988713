import unionWith from 'lodash/unionWith';
import isEqual from 'lodash/isEqual';
import {
  REQUEST_EVENTS,
  RECEIVE_EVENTS,
  INVALIDATE_EVENTS,
} from '../actions/actionTypes';

function events(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action,
) {
  switch (action.type) {
    case RECEIVE_EVENTS:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.refresh
          ? action.events
          : unionWith(state.items, action.events, isEqual),
        lastUpdated: action.receivedAt,
        nextUrl: action.nextUrl,
        totalItems: action.totalItems,
      };
    case REQUEST_EVENTS:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case INVALIDATE_EVENTS:
      return {
        ...state,
        didInvalidate: true,
        items: [],
        nextUrl: null,
      };
    default:
      return state;
  }
}

function eventsByType(
  state = {
    hosted: {},
    templates: {},
    ongoing: {},
    recent: {},
    joined: {},
    invitations: {},
    network: {},
    past: {},
    last: {},
    places: {},
    drafts: {},
  },
  action,
) {
  switch (action.type) {
    case RECEIVE_EVENTS:
    case INVALIDATE_EVENTS:
    case REQUEST_EVENTS:
      return {
        ...state,
        [action.eventType]: events(state[action.eventType], action),
      };
    default:
      return state;
  }
}

export default eventsByType;
