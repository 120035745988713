import React from 'react';
import { View, Text, Platform } from 'react-native';
import { connect } from 'react-redux';
import { SafeAreaView } from 'react-navigation';
import Button from '../UI/Button';
import { hideInfoMessage } from '../../store/actions/ui';

const mapStateToProps = state => ({
  ...state.ui.infoMessage,
});

const mapDispatchToProps = dispatch => ({
  hideInfoMessage: () => dispatch(hideInfoMessage()),
});

function InfoMessage(props) {
  const { isVisible, backgroundColor, color, title, text, button } = props;
  if (!isVisible) {
    return null;
  }
  return (
    <SafeAreaView
      forceInset={{ bottom: 'never' }}
      style={{
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor,
      }}
    >
      <View style={{ flex: 1 }}>
        <Text style={{ fontSize: 17, fontWeight: 'bold', color }}>{title}</Text>
        <Text style={{ fontSize: 17, color, paddingVertical: 10 }}>{text}</Text>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button
            style={{ marginRight: 10, flex: 1 }}
            size={'medium'}
            text={'Ist mir egal'}
            action={() => {
              if (props.onDismiss) {
                props.onDismiss();
              }
              props.hideInfoMessage();
            }}
            color={'white'}
            backgroundColor={'rgba(0,0,0,0.2)'}
          />
          <Button
            style={{ flexBasis: Platform.OS === 'web' ? 'auto' : 0 }}
            size={'medium'}
            text={button.text}
            action={button.action}
            color={backgroundColor}
            backgroundColor={color}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoMessage);
