import { UPDATE_ENTITIES, UPDATE_IDS } from './actionTypes';

export function updateEntities(normalizedData) {
  return {
    type: UPDATE_ENTITIES,
    ...normalizedData,
  };
}

export function updateIDs(normalizedData, entity) {
  return {
    type: UPDATE_IDS,
    ...normalizedData,
    entity,
  };
}
