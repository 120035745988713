import dayjs from 'dayjs';

export function getHumanizedDuration(milliseconds) {
  const minutes = (milliseconds / (1000 * 60)) % 60;
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
  const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
  const dayString = days > 0 ? `${days}d ` : '';
  const hourString = hours > 0 ? `${hours}h ` : '';
  const minuteString =
    minutes > 0 ? `${minutes}${hours > 0 ? '' : ' min'}` : '';
  return dayString + hourString + minuteString;
}

export function roundToQuarterHour(dateObject) {
  const dateTime = dayjs(dateObject)
    .second(0)
    .millisecond(0);
  if (dateTime.minute() >= 45) {
    dateTime.hour(dateTime.hour() + 1);
    dateTime.minute(0);
  }
  const roundedMinute = Math.round(dateTime.minute() / 15) * 15;
  dateTime.minute(roundedMinute);
  return dateTime.toDate();
}

export function getDuration(startTime, endTime) {
  // Normalize:
  const start = roundToQuarterHour(startTime);
  const end = roundToQuarterHour(endTime);
  // Get difference:
  return dayjs(end).valueOf() - dayjs(start).valueOf();
}

export function getNextQuarterHour() {
  const dateTime = dayjs()
    .second(0)
    .millisecond(0);
  if (dateTime.minute() >= 45) {
    dateTime.hour(dateTime.hour() + 1);
    dateTime.minute(0);
  }
  const roundedMinute = Math.ceil(dateTime.minute() / 15) * 15;
  dateTime.minute(roundedMinute);
  return dateTime.toDate();
}
