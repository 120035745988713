import React from 'react';
import { Text } from 'react-native';
import getThemeStyles from '../../../settings/themes';
import { isRowLayout } from '../../../utilities/screen';

function BoldCellValue(props) {
  return (
    <Text
      style={{
        fontSize: 17,
        fontWeight: 'bold',
        color:
          props.theme && !isRowLayout
            ? getThemeStyles(props.theme).color
            : 'black',
      }}
      numberOfLines={1}
      ellipsizeMode={'tail'}
    >
      {props.text}
    </Text>
  );
}

export default BoldCellValue;
