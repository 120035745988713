import React from 'react';
import { StyleSheet, View, Switch, Text } from 'react-native';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { COLORS } from '../../settings/colors';
import { CALENDAR, getLocale } from '../../utilities/i18n';
import { setInvitation } from '../../store/actions/index';
import { capitalizeFirstLetter } from '../../utilities/strings';
import Cell from '../UI/Cells/Cell';
import BoldCellValue from '../UI/Cells/BoldCellValue';
import { FullScreenModalWithBottomAction } from '../UI/Modals';
import getThemeStyles from '../../settings/themes';

const mapDispatchToProps = dispatch => ({
  setInvitation: invitation => dispatch(setInvitation(invitation)),
});

const mapStateToProps = state => ({
  ends_at: state.ui.invitation.ends_at ? state.ui.invitation.ends_at : null,
  starts_at: state.ui.invitation.starts_at,
});

const styles = StyleSheet.create({
  switchContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 15,
  },
  bold: {
    fontWeight: 'bold',
  },
  opaque: {
    opacity: 0.6,
  },
});

class EndTimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DatePickerComponent: null,
    };
  }

  async componentDidMount() {
    const DatePickerComponent = await import(
      '../../components/pickers/DateTimePickerWeb'
    );
    this.setState({ DatePickerComponent: DatePickerComponent.default });
  }

  locale = getLocale().slice(0, 2);

  renderDatePicker = () => {
    const { DatePickerComponent } = this.state;
    if (!DatePickerComponent) {
      return null;
    }
    return (
      <View style={{ alignItems: 'center' }}>
        <DatePickerComponent
          selected={new Date(this.props.ends_at)}
          onChange={this.onDateChange}
          minDate={new Date()}
          locale={this.locale}
        />
      </View>
    );
  };

  onDateChange = ends_at => this.props.setInvitation({ ends_at });

  getValue = () =>
    this.props.ends_at ? (
      <BoldCellValue
        theme={this.props.theme}
        text={capitalizeFirstLetter(
          dayjs(this.props.ends_at).calendar(
            null,
            CALENDAR.withTime[this.locale],
          ),
        )}
      />
    ) : (
      <Text
        style={{
          opacity: 0.6,
          fontSize: 17,
          color: this.props.theme
            ? getThemeStyles(this.props.theme).color
            : 'black',
        }}
      >
        Offen
      </Text>
    );

  setOpenEnd = () => {
    this.props.setInvitation({ ends_at: null });
    this.props.toggleCell('ends_at');
  };

  renderExpansion = () => (
    <FullScreenModalWithBottomAction
      isVisible={this.props.isExpanded}
      onClose={() => this.props.toggleCell('ends_at')}
      title={'Ende'}
    >
      <View style={styles.switchContainer}>
        <Text>Offenes Ende</Text>
        <Switch
          trackColor={COLORS.berry}
          value={!this.props.ends_at}
          onValueChange={this.setOpenEnd}
        />
      </View>
      <View>{this.props.ends_at && this.renderDatePicker()}</View>
    </FullScreenModalWithBottomAction>
  );

  render = () => {
    return (
      <Cell
        title={'Ende'}
        RightComponent={this.getValue()}
        ExpansionComponent={this.renderExpansion()}
        {...this.props}
        onPress={() =>
          !this.props.isExpanded && this.props.toggleCell('ends_at')
        }
      />
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EndTimePicker);
