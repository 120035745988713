/* eslint-disable consistent-return */
import * as Sentry from '@sentry/react-native';
import React from 'react';
import { Platform, View, StatusBar, Text } from 'react-native';
import { createBrowserApp } from '@react-navigation/web';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { connect } from 'react-redux';
import AsyncStorage from './storage';
import NavigationService from './src/utilities/NavigationService';
import './node_modules/dayjs/locale/de-ch';
import './node_modules/dayjs/locale/de-at';
import './node_modules/dayjs/locale/de';
import {
  authSetToken,
  updateUser,
  fetchUnreadItems,
  refreshAllEvents,
  fetchConversations,
  fetchEventConversations,
  startLoading,
  stopLoading,
  fetchBookings,
  setLastDataUpdate,
} from './src/store/actions/index';
import { NoNetworkHeader, FullScreenSpinner } from './src/components/index';
import InfoMessage from './src/components/informations/InfoMessage';
import { getLocale } from './src/utilities/i18n';
import { isRowLayout } from './src/utilities/screen';
import { logCurrentScreen } from './src/utilities/logging';
import firebaseConfig from './firebaseConfig';

// Extend Day.JS with plugins:
dayjs.extend(calendar);
dayjs.extend(isSameOrAfter);

if (process.env?.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://c138fa8962f24a588d6f58610b786ce0@sentry.io/2845417',
    release: `triff@${process.env.npm_package_version}`,
  });
}

if (Text.defaultProps == null) Text.defaultProps = {};
Text.defaultProps.style = { fontSize: 17 };

const mapDispatchToProps = dispatch => ({
  authSetToken: token => dispatch(authSetToken(token)),
  updateUser: token => dispatch(updateUser(token)),
  fetchUnreadItems: () => dispatch(fetchUnreadItems()),
  refreshAllEvents: () => dispatch(refreshAllEvents()),
  fetchConversations: () => dispatch(fetchConversations()),
  fetchEventConversations: () => dispatch(fetchEventConversations()),
  startLoading: screen => dispatch(startLoading(screen)),
  stopLoading: screen => dispatch(stopLoading(screen)),
  fetchBookings: () => dispatch(fetchBookings()),
  setLastDataUpdate: () => dispatch(setLastDataUpdate()),
});

const mapStateToProps = state => ({
  unreadItems: state.ui.notifications,
  phoneContacts: state.account.phoneContacts,
  token: state.auth.token,
  app_version: state.account.app_version,
  lastDataUpdate: state.ui.data.lastUpdate,
});

const prefix = Platform.select({
  android: 'triff://triff/',
  ios: 'triff://',
  // TODO: Is this needed?
  // web: 'http://localhost:8080',
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MainComponent: null,
    };
  }

  async getMainComponent() {
    // TODO: Change redirect after logging in (this is only run once)
    if (!this.props.token) {
      // User has no account.
      const PublicRoutes = await import('./src/navigators/PublicRoutes');
      return this.setState({ MainComponent: PublicRoutes.default });
    }
    if (isRowLayout) {
      const WebSwitch = await import('./src/navigators/WebSwitch');
      this.setState({
        MainComponent: createBrowserApp(WebSwitch.default),
      });
    } else {
      const MainTabs = await import('./src/navigators/MainTabs');
      this.setState({
        MainComponent: createBrowserApp(MainTabs.default),
      });
    }
  }

  setDayJSLocale = () => {
    let locale = getLocale();
    locale = locale.replace('_', '-').toLowerCase();
    // Only change locale if we have imported the localization:
    if (['de', 'de-ch', 'de-at'].includes(locale)) {
      dayjs.locale(locale);
    }
  };

  async componentDidMount() {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    firebase.analytics().setAnalyticsCollectionEnabled(true);
    firebase.analytics().setCurrentScreen('App');
    firebase.analytics().logEvent('open_web_app');
    this.getMainComponent();
    this.setDayJSLocale();
    // Update data if user is registered:
    const token = await this.getAuthToken();
    if (!token) return;
    this.props.authSetToken({ token });
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      this.getMainComponent();
      this.updateData();
    }
  }

  async updateData() {
    if (!this.props.token) return;
    this.props.fetchUnreadItems();
    await this.props.updateUser(this.props.token);
    this.fetchMainData();
    this.props.setLastDataUpdate();
  }

  getAuthToken() {
    return AsyncStorage.get('triff:auth:token').catch(e => {
      Sentry.captureException(e);
      return Promise.reject(e);
    });
  }

  fetchMainData() {
    this.props.refreshAllEvents();
    this.fetchConversations();
    this.props.fetchBookings();
  }

  fetchConversations() {
    this.props.startLoading('conversations');
    this.props.fetchConversations();
    this.props.fetchEventConversations();
    this.props.stopLoading('conversations');
  }

  render() {
    const { MainComponent } = this.state;
    if (!MainComponent) {
      return <FullScreenSpinner />;
    }
    return (
      <View style={{ flex: 1 }}>
        <NoNetworkHeader />
        <InfoMessage />
        <StatusBar barStyle={'dark-content'} backgroundColor={'white'} />
        <MainComponent
          uriPrefix={prefix}
          ref={navigatorRef => {
            NavigationService.setTopLevelNavigator(navigatorRef);
          }}
          onNavigationStateChange={logCurrentScreen}
        />
      </View>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

// Hides warnings about debugger in background tab. Useful for development process.
console.ignoredYellowBox = [
  'Remote debugger',
  'Warning: componentWillMount is deprecated',
  'Warning: componentWillReceiveProps is deprecated',
];

console.disableYellowBox = true;
