/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react-native';
import { normalize } from 'normalizr';
import AsyncStorage from '../../../storage';
import schemata from '../schemata';
import { host } from '../../settings/environment';
import { updateEntities, updateIDs } from './index';

function getAuthToken() {
  return AsyncStorage.get('triff:auth:token')
    .catch(err => {
      Sentry.captureException(err);
      Promise.reject(err);
    })
    .then(token => token);
}

export function fetchBookings() {
  return async dispatch => {
    return fetch(`${host}/api/payments/bookings/user/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${await getAuthToken()}`,
      },
    })
      .then(
        response => {
          if (!response.ok) {
            throw new Error(response.status);
          }
          return response.json().then(bookings => {
            const normalizedData = normalize(bookings, [schemata.booking]);
            dispatch(updateEntities(normalizedData));
            dispatch(updateIDs(normalizedData, 'bookings'));
          });
        },
        error => {
          throw new Error(error);
        },
      )
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  };
}
