import React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import TabButton from './TabButton';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tabs: {
    flex: 0,
    flexBasis: Platform.OS === 'web' && 'auto',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
  content: {
    flex: 1,
    padding: 10,
    justifyContent: 'center',
  },
  button: {},
});

export default class TabbedView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab,
    };
  }

  toggleTab = id => this.setState({ activeTab: id });

  renderTabs() {
    return this.props.tabs.map(tab => (
      <TabButton
        key={tab.id}
        onPress={() => {
          if (tab.onTabPress) {
            tab.onTabPress();
          }
          this.toggleTab(tab.id);
        }}
        isActive={this.state.activeTab === tab.id}
        {...tab}
      />
    ));
  }

  renderContent() {
    return this.props.tabs.find(tab => tab.id === this.state.activeTab).content;
  }

  render = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: this.props.inverted ? 'column-reverse' : 'column',
        }}
      >
        <View style={styles.tabs}>{this.renderTabs()}</View>
        <View style={styles.content}>{this.renderContent()}</View>
      </View>
    );
  };
}
