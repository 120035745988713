import React from 'react';
import { Picker, Text, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import Cell from '../UI/Cells/Cell';
import { setInvitation } from '../../store/actions/index';
import getThemeStyles from '../../settings/themes';
import BoldCellValue from '../UI/Cells/BoldCellValue';
import { FullScreenModalWithBottomAction } from '../UI/Modals';

const mapDispatchToProps = dispatch => ({
  setInvitation: invitation => dispatch(setInvitation(invitation)),
});

const mapStateToProps = state => ({
  category: state.ui.invitation.category,
});

const styles = StyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },
  opaque: {
    opacity: 0.6,
  },
});

class CategoryPicker extends React.PureComponent {
  categories = {
    null: 'Bitte wählen',
    eating_and_drinking: 'Essen',
    sports: 'Sport',
    culture: 'Kultur',
    excursion: 'Ausflug',
    nightlife: 'Nachtleben',
    hobby: 'Hobby',
    other: 'Anderes',
  };

  renderExpansion = () => (
    <FullScreenModalWithBottomAction
      title={'Kategorie'}
      isVisible={this.props.isExpanded}
      onClose={() => this.props.toggleCell('category')}
    >
      <Picker
        onValueChange={this.setCategory}
        selectedValue={this.props.category}
      >
        {Object.keys(this.categories).map(category => (
          <Picker.Item
            label={this.categories[category]}
            value={category}
            key={category}
          />
        ))}
      </Picker>
    </FullScreenModalWithBottomAction>
  );

  getValue = () =>
    this.props.category ? (
      <BoldCellValue
        theme={this.props.theme}
        text={this.categories[this.props.category]}
      />
    ) : (
      <Text
        style={[
          styles.opaque,
          {
            fontSize: 17,
            color: this.props.theme
              ? getThemeStyles(this.props.theme).color
              : 'black',
          },
        ]}
      >
        Wählen
      </Text>
    );

  setCategory = category => this.props.setInvitation({ category });

  render = () => {
    return (
      <Cell
        title={'Kategorie'}
        RightComponent={this.getValue()}
        ExpansionComponent={this.renderExpansion()}
        isExpanded={this.props.isExpanded}
        {...this.props}
        onPress={() =>
          !this.props.isExpanded && this.props.toggleCell('category')
        }
      />
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPicker);
