import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import MultiSlider from '@ptomasroos/react-native-multi-slider';
import Cell from '../UI/Cells/Cell';
import { setInvitation } from '../../store/actions/index';
import getThemeStyles from '../../settings/themes';
import FullScreenModalWithBottomAction from '../modals/FullScreenModalWithBottomAction';
import { COLORS } from '../../settings/colors';
import SliderMarker from '../slider/SliderMarker';
import BoldCellValue from '../UI/Cells/BoldCellValue';

const mapDispatchToProps = dispatch => ({
  setInvitation: invitation => dispatch(setInvitation(invitation)),
});

const mapStateToProps = state => ({
  min_participants: state.ui.invitation.min_participants,
  max_participants: state.ui.invitation.max_participants,
  token: state.auth.token,
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  opaque: {
    opacity: 0.6,
  },
  sliderContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
    maxWidth: '100%',
  },
  markerStyle: {
    height: 30,
    width: 30,
    borderRadius: 15,
    backgroundColor: COLORS.berry,
    shadowOffset: { width: 0, height: 0 },
  },
});

class ParticipantsPicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      participantLimits: [
        props.min_participants || 1,
        props.max_participants || 20,
      ],
      sliderWidth: 0,
    };
  }

  setParticipantLimits = ([min_participants, max_participants]) => {
    let min = min_participants;
    let max = max_participants;
    if (min_participants === 1) {
      min = null;
    }
    if (max_participants === 20) {
      max = null;
    }
    this.props.setInvitation({ min_participants: min, max_participants: max });
  };

  renderExpansion = () => {
    return (
      <FullScreenModalWithBottomAction
        isVisible={this.props.isExpanded}
        onClose={() => this.props.toggleCell('participants')}
        title={'Teilnehmer'}
        onLayout={({ nativeEvent }) =>
          this.setState({ sliderWidth: nativeEvent.layout.width - 50 })
        }
      >
        <MultiSlider
          isMarkersSeparated
          allowOverlap
          snapped
          values={this.state.participantLimits}
          min={1}
          max={20}
          step={1}
          onValuesChangeStart={this.props.disableScroll}
          onValuesChangeFinish={this.setParticipantLimits}
          onValuesChange={participantLimits =>
            this.setState({ participantLimits })
          }
          sliderLength={this.state.sliderWidth}
          containerStyle={styles.sliderContainer}
          selectedStyle={{ backgroundColor: COLORS.berry, height: 3 }}
          unselectedStyle={{ backgroundColor: 'rgba(0,0,0,0.2)', height: 3 }}
          markerStyle={styles.markerStyle}
          pressedMarkerStyle={styles.pressedMarkerStyle}
          customMarkerLeft={props => (
            <SliderMarker {...props} caption={'min'} />
          )}
          customMarkerRight={props => (
            <SliderMarker
              {...props}
              caption={'max'}
              customValue={props.currentValue === 20 ? '∞' : props.currentValue}
            />
          )}
        />
      </FullScreenModalWithBottomAction>
    );
  };

  getValue = () => {
    const min = this.props.min_participants;
    const max = this.props.max_participants;
    let text;
    if (!min && !max) {
      return (
        <Text
          style={[
            styles.opaque,
            {
              fontSize: 17,
              color: this.props.theme
                ? getThemeStyles(this.props.theme).color
                : 'black',
            },
          ]}
        >
          Unbeschränkt
        </Text>
      );
    }
    if (min) {
      text = `min. ${min}`;
    }
    if (max) {
      text = `max. ${max}`;
    }
    if (min && max) {
      text = `${min}-${max}`;
    }

    return <BoldCellValue theme={this.props.theme} text={text} />;
  };

  render = () => {
    return (
      <Cell
        title={'Teilnehmer'}
        RightComponent={this.getValue()}
        ExpansionComponent={this.renderExpansion()}
        {...this.props}
        onPress={() =>
          !this.props.isExpanded && this.props.toggleCell('participants')
        }
      />
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsPicker);
