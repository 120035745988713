import React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialIcons';
import SwitchCell from '../UI/Cells/SwitchCell';
import { setInvitation } from '../../store/actions/index';
import { getThemeStyles } from '../../settings/UISettings';
import RoundedModal from '../modals/RoundedModal';

const mapDispatchToProps = dispatch => ({
  setInvitation: invitation => dispatch(setInvitation(invitation)),
});

const mapStateToProps = state => ({
  instant_booking: state.ui.invitation.instant_booking,
});

class InstantBookingSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  toggleInstantBooking = instant_booking =>
    this.props.setInvitation({ instant_booking });

  getExplanation = instant_booking =>
    instant_booking
      ? 'Teilnehmer können sich anmelden, ohne dass du sie bestätigen musst.'
      : 'Interessierte müssen dir zuerst eine Anfrage schicken, um teilzunehmen.';

  renderMiddleComponent() {
    const { theme } = this.props;
    const color = theme ? getThemeStyles(theme).color : 'black';
    return (
      <TouchableOpacity
        style={{ flexDirection: 'row', alignItems: 'center' }}
        onPress={() => this.setState({ isModalVisible: true })}
      >
        <Text style={{ fontSize: 17, color, marginRight: 5 }}>
          Sofort teilnehmen
        </Text>
        <Icon name={'help-outline'} color={color} size={17} />
      </TouchableOpacity>
    );
  }

  render = () => {
    const { instant_booking } = this.props;
    return (
      <View>
        <SwitchCell
          MiddleComponent={this.renderMiddleComponent()}
          value={instant_booking}
          onValueChange={this.toggleInstantBooking}
          {...this.props}
        />
        <RoundedModal
          isVisible={this.state.isModalVisible}
          onClose={() => this.setState({ isModalVisible: false })}
        >
          <Text>
            Ist diese Option gewählt, können sich Teilnehmer anmelden, ohne dir
            vorher eine Anfrage zu schicken.
          </Text>
        </RoundedModal>
      </View>
    );
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstantBookingSwitch);
