import React from 'react';
import { View, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import Badge from './Badge';

const styles = StyleSheet.create({
  badgeContainer: {
    position: 'absolute',
    top: -5,
    right: -10,
  },
});

class BadgeIcon extends React.Component {
  renderBadge(type) {
    const {
      events,
      participations,
      messages,
      relationships,
      groups,
      guestbook,
    } = this.props.unreadItems;
    switch (type) {
      case 'calendar':
        if (events.length > 0 || participations.length > 0) {
          return (
            <View style={styles.badgeContainer}>
              <Badge border number={events.length + participations.length} />
            </View>
          );
        }
        return null;
      case 'messages':
        if (messages.length > 0) {
          return (
            <View style={styles.badgeContainer}>
              <Badge border number={messages.length} />
            </View>
          );
        }
        return null;
      case 'account':
        if (
          relationships.length > 0 ||
          groups.length > 0 ||
          guestbook.length > 0
        ) {
          return (
            <View style={styles.badgeContainer}>
              <Badge
                border
                number={relationships.length + groups.length + guestbook.length}
              />
            </View>
          );
        }
        return null;
      default:
        return null;
    }
  }

  render = () => {
    return (
      <View>
        {this.props.icon}
        {this.renderBadge(this.props.type)}
      </View>
    );
  };
}

const mapStateToProps = state => ({
  unreadItems: state.ui.notifications,
});

export default connect(mapStateToProps)(BadgeIcon);
