import React from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import { P } from './TextStyles';

const styles = StyleSheet.create({
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF70',
    zIndex: 99,
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export function FullScreenSpinner(props) {
  if (!props.isLoading) {
    return null;
  }
  return (
    <View style={styles.loading}>
      <ActivityIndicator size={'large'} />
    </View>
  );
}

export function CenteredSpinner(props) {
  const { size, color, text } = props;
  return (
    <View style={styles.centered}>
      <ActivityIndicator size={size || 'large'} color={color} />
      <P
        centered
        style={{
          color,
          marginTop: 10,
        }}
      >
        {text}
      </P>
    </View>
  );
}
