import * as Sentry from '@sentry/react-native';
import { normalize } from 'normalizr';
import AsyncStorage from '../../../storage';
import schemata from '../schemata';
import { ADD_MESSAGE, DELETE_MESSAGE, DELETE_LOCAL_MEDIA } from './actionTypes';
import { host } from '../../settings/environment';
import { updateEntities, updateIDs } from './index';

function getAuthToken() {
  return AsyncStorage.get('triff:auth:token')
    .catch(err => {
      Sentry.captureException(err);
      Promise.reject(new Error(err));
    })
    .then(token => token);
}

export function deleteLocalMedia(messageUUID) {
  return {
    type: DELETE_LOCAL_MEDIA,
    uuid: messageUUID,
  };
}

export function addMessage(message) {
  return {
    type: ADD_MESSAGE,
    message,
  };
}

export function deleteMessage(message) {
  return {
    type: DELETE_MESSAGE,
    message,
  };
}

export function fetchConversations() {
  return async dispatch => {
    return fetch(`${host}/api/conversations/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${await getAuthToken()}`,
      },
    })
      .then(
        response => {
          if (!response.ok) {
            throw new Error(response.status);
          }
          return response.json().then(conversations => {
            const normalizedData = normalize(conversations, [
              schemata.conversation,
            ]);
            dispatch(updateEntities(normalizedData));
            dispatch(updateIDs(normalizedData, 'conversations'));
            return conversations;
          });
        },
        error => {
          throw error;
        },
      )
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  };
}

export function fetchEventConversations() {
  return async dispatch => {
    return fetch(`${host}/api/conversations/events/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${await getAuthToken()}`,
      },
    })
      .then(
        response => {
          if (!response.ok) {
            throw new Error(response.status);
          }
          return response.json().then(conversations => {
            const normalizedData = normalize(conversations, [
              schemata.conversation,
            ]);
            dispatch(updateEntities(normalizedData));
            dispatch(updateIDs(normalizedData, 'conversations'));
            return conversations;
          });
        },
        error => {
          throw new Error(error);
        },
      )
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  };
}

export function fetchConversation(id) {
  return async dispatch => {
    return fetch(`${host}/api/conversations/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${await getAuthToken()}`,
      },
    })
      .then(
        response => {
          if (!response.ok) {
            throw new Error(response.status);
          }
          return response.json().then(conversation => {
            const normalizedData = normalize(
              conversation,
              schemata.conversation,
            );
            dispatch(updateEntities(normalizedData));
            return conversation;
          });
        },
        error => {
          throw new Error(error);
        },
      )
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  };
}
