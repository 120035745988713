import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import ui from './reducers/ui';
import auth from './reducers/auth';
import account from './reducers/account';
import entities from './reducers/entities';

const appReducer = combineReducers({
  ui,
  auth,
  account,
  entities,
});

let composeEnhancers = compose;

// Redux debugger in Dev Mode
if (__DEV__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const rootReducer = (state, action) => {
  if (action.type === 'DELETE_ACCOUNT') {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  keyPrefix: '',
  version: 1,
  throttle: 1000,
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk)),
  );
  const persistor = persistStore(store);
  return { store, persistor };
};
