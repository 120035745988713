import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  Image as RNImage,
  Platform,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import Avatar from '../../avatar/Avatar';
import { smallFont, getThemeStyles } from '../../../settings/UISettings';
import { isRowLayout } from '../../../utilities/screen';

const Image =
  Platform.OS === 'web' ? RNImage : require('react-native-fast-image').default;

const styles = StyleSheet.create({
  container: {
    borderBottomColor: 'rgba(0,0,0,0.5)',
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  cell: {
    backgroundColor: 'white',
    justifyContent: 'space-between',
  },
  explanationContainer: {
    padding: 10,
    opacity: 0.7,
    borderTopColor: 'rgba(0,0,0,0.5)',
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  explanationText: {
    fontSize: 13,
  },
  header: {
    paddingHorizontal: 10,
    paddingVertical: 0,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    flex: 0,
  },
  textContainer: {
    justifyContent: 'center',
  },
  right: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 10,
    minWidth: 30,
  },
  selectedValue: {
    color: 'rgba(0, 0, 0, 0.5)',
    marginRight: 10,
  },
});

export default class Cell extends React.Component {
  renderLeft = () => {
    const {
      LeftComponent,
      icon,
      avatar,
      image,
      berryBackground,
      initials,
    } = this.props;
    if (icon || avatar || image || initials) {
      let content = (
        <Icon name={icon} size={30} color={berryBackground ? 'white' : null} />
      );
      if (image) {
        content = <Image source={image} style={{ height: 30, width: 42 }} />;
      }
      if (avatar || initials) {
        content = <Avatar source={avatar} initials={initials} size={30} />;
      }
      return <View style={styles.image}>{content}</View>;
    }
    if (LeftComponent) {
      return LeftComponent;
    }
    return null;
  };

  getTextColor = () => {
    const { berryBackground, negative, theme } = this.props;
    if (theme) {
      if (isRowLayout) {
        return 'black';
      }
      return getThemeStyles(theme).color;
    }
    if (berryBackground) {
      return 'white';
    }
    if (negative === false) {
      // not undefined
      return 'mediumseagreen';
    }
    if (negative) {
      return 'salmon';
    }
    return null;
  };

  renderMiddle() {
    const flex = this.renderLeft() ? 1 : 0;
    if (this.props.MiddleComponent) {
      return (
        <View style={[styles.textContainer, { flex }]}>
          {this.props.MiddleComponent}
        </View>
      );
    }
    return (
      <View style={[styles.textContainer, { flex }]}>
        <Text
          numberOfLines={1}
          ellipsizeMode={'tail'}
          style={[
            { color: this.getTextColor(), fontSize: 17 },
            this.props.titleStyle,
          ]}
        >
          {this.props.title}
        </Text>
        {this.props.subtitle && (
          <Text
            numberOfLines={1}
            ellipsizeMode={'tail'}
            style={{
              fontSize: smallFont,
              color: this.getTextColor(),
              opacity: 0.7,
              // marginTop: 5,
            }}
          >
            {this.props.subtitle}
          </Text>
        )}
      </View>
    );
  }

  renderRight = () => (
    <View style={[styles.right, { flex: this.renderLeft() ? 0 : 1 }]}>
      {this.props.selectedValue && (
        <Text
          style={[
            styles.selectedValue,
            { fontSize: 17, color: this.getTextColor(), opacity: 0.5 },
          ]}
        >
          {this.props.selectedValue}
        </Text>
      )}
      {this.props.RightComponent}
    </View>
  );

  renderExplanation = () => (
    <View style={styles.explanationContainer}>
      <Text style={[styles.explanationText, { color: this.getTextColor() }]}>
        {this.props.explanation}
      </Text>
    </View>
  );

  render = () => {
    const {
      onPress,
      small,
      isExpanded,
      ExpansionComponent,
      explanation,
      disabled,
      theme,
      backgroundColor,
    } = this.props;
    return (
      <TouchableWithoutFeedback onPress={disabled ? () => {} : onPress}>
        <View
          style={{
            opacity: disabled ? 0.5 : 1,
            borderBottomColor: theme
              ? getThemeStyles(theme).backgroundTranslucent
              : 'rgba(0,0,0,0.1)',
            borderBottomWidth: StyleSheet.hairlineWidth,
          }}
        >
          <View
            style={[
              styles.cell,
              {
                backgroundColor:
                  backgroundColor || (theme ? 'transparent' : 'white'),
              },
            ]}
          >
            <View style={[styles.header, { minHeight: small ? 40 : 60 }]}>
              <View
                style={{
                  flexBasis: Platform.OS === 'web' ? 'auto' : undefined,
                }}
              >
                {this.renderLeft()}
              </View>
              <View
                style={{
                  flexBasis: Platform.OS === 'web' ? 'auto' : undefined,
                  flexGrow: 1,
                }}
              >
                {this.renderMiddle()}
              </View>
              {this.renderRight()}
            </View>
            {isExpanded && ExpansionComponent}
          </View>
          {explanation && this.renderExplanation()}
        </View>
      </TouchableWithoutFeedback>
    );
  };
}
