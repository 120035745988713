export const TRY_AUTH = 'TRY_AUTH';

export const UI_START_LOADING = 'UI_START_LOADING';
export const UI_STOP_LOADING = 'UI_STOP_LOADING';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const SET_NETWORK_DATA = 'SET_NETWORK_DATA';

export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_REMOVE_TOKEN = 'AUTH_REMOVE_TOKEN';
export const AUTH_CONFIRM_CODE = 'AUTH_CONFIRM_CODE';

export const INVITE_PARTICIPANTS = 'INVITE_PARTICIPANTS';

export const UPDATE_ACCOUNT_IMAGE = 'UPDATE_ACCOUNT_IMAGE';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const GET_WALLET_BALANCE = 'GET_WALLET_BALANCE';
export const DELETE_CREDIT_CARD = 'DELETE_CREDIT_CARD';
export const UPDATE_IBAN = 'UPDATE_IBAN';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const UPDATE_TRIFF_ID = 'UPDATE_TRIFF_ID';

export const STORE_PHONECONTACTS = 'STORE_PHONECONTACTS';
export const STORE_TRIFF_FRIENDS = 'STORE_TRIFF_FRIENDS';
export const GET_GROUPS = 'GET_GROUPS';
export const UPDATE_TRIFF_ADDRESS_BOOK = 'UPDATE_TRIFF_ADDRESS_BOOK'; // this defines the triff address book --> ideally phone and server contacts or just server contacts.

export const SET_UNREAD_ITEMS = 'SET_UNREAD_ITEMS';
export const DELETE_UNREAD_ITEMS = 'DELETE_UNREAD_ITEMS';

export const SET_INVITATION = 'SET_INVITATION';
export const RESET_INVITATION = 'RESET_INVITATION';
export const EDIT_INVITATION = 'EDIT_INVITATION';

export const RECEIVE_EVENTS = 'RECEIVE_EVENTS';
export const REQUEST_EVENTS = 'REQUEST_EVENTS';
export const INVALIDATE_EVENTS = 'INVALIDATE_EVENTS';

export const SET_CONVERSATIONS = 'SET_CONVERSATIONS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_NEW_MESSAGE = 'SET_NEW_MESSAGE';
export const CLEAR_NEW_MESSAGE = 'CLEAR_NEW_MESSAGE';
export const DELETE_LOCAL_MEDIA = 'DELETE_LOCAL_MEDIA';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';

export const UPDATE_ENTITIES = 'UPDATE_ENTITIES';
export const UPDATE_IDS = 'UPDATE_IDS';
export const ADD_CHECKLIST = 'ADD_CHECKLIST';

export const LOGGING = 'LOGGING';

export const SHOW_INFO_MESSAGE = 'SHOW_INFO_MESSAGE';
export const HIDE_INFO_MESSAGE = 'HIDE_INFO_MESSAGE';

export const SET_LAST_DATA_UPDATE = 'SET_LAST_DATA_UPDATE';

// This file is used to define the different action types.
// the actions are then defined in the respective file (e.g. places.js)
