import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { connect } from 'react-redux';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { FullScreenHeaderModal } from '../UI/Modals';
import Cell from '../UI/Cells/Cell';
import { setInvitation } from '../../store/actions/index';
import { COLORS } from '../../settings/colors';
import { GOOGLE_MAPS_API_KEY } from '../../settings/environment';
import getThemeStyles from '../../settings/themes';
import BoldCellValue from '../UI/Cells/BoldCellValue';

const mapDispatchToProps = dispatch => ({
  setInvitation: invitation => dispatch(setInvitation(invitation)),
});

const mapStateToProps = state => ({
  place: state.ui.invitation.place,
  userPlaces: state.account.places,
  placesByID: state.entities.places.byID,
});

const styles = StyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },
  opaque: {
    opacity: 0.6,
  },
});

class PlacePicker extends React.PureComponent {
  setPlace = (data, details = null) => {
    // 'details' is provided if fetchDetails = true
    let place;
    // TODO: Also use places from entities. Or refactor to only use entities.
    if (data.isPredefinedPlace) {
      place = this.props.userPlaces.features.find(p => p.id === data.id);
      place.isPredefined = true;
    } else {
      place = {
        geometry: {
          type: 'Point',
          coordinates: details.geometry.location,
        },
        properties: {
          address: details.formatted_address,
        },
      };
    }
    this.props.setInvitation({ place });
    this.props.toggleCell('place');
  };

  createPredefinedPlaces() {
    // TODO: Also use places from entities. Or refactor to only use entities.
    const venues = this.props.userPlaces.features.filter(
      item => item.properties.type === 'venue',
    );
    const predefinedPlaces = venues.map(place => ({
      id: place.id,
      isPredefinedPlace: true,
      description: place.properties.description || place.properties.address,
      geometry: place.geometry,
    }));
    return predefinedPlaces;
  }

  styles = {
    textInputContainer: {
      width: '100%',
      backgroundColor: 'white',
    },
    textInput: {
      backgroundColor: '#00000010',
    },
    predefinedPlacesDescription: {
      fontWeight: 'bold',
    },
    poweredContainer: {
      justifyContent: 'flex-start',
    },
  };

  deletePlace = () => {
    this.props.setInvitation({ place: null });
    this.props.toggleCell('place');
  };

  renderDelete() {
    if (this.props.place) {
      return <Text style={{ color: COLORS.berry }}>Ort löschen</Text>;
    }
    return null;
  }

  renderModal = () => (
    <FullScreenHeaderModal
      isVisible={this.props.isExpanded}
      onClose={() => this.props.toggleCell('place')}
      headerRightAction={this.deletePlace}
      headerRightComponent={this.renderDelete()}
    >
      <GooglePlacesAutocomplete
        autoFocus
        fetchDetails
        placeholder={'Ort oder Adresse eingeben'}
        minLength={2}
        returnKeyType={'search'}
        listViewDisplayed={'auto'}
        keyboardShouldPersistTaps={'handled'}
        textInputProps={{ autoCorrect: false }}
        renderDescription={row => row.description}
        onPress={this.setPlace}
        getDefaultValue={this.props.getDefaultValue}
        query={{
          key: GOOGLE_MAPS_API_KEY,
          language: 'de',
        }}
        styles={this.styles}
        nearbyPlacesAPI={'GooglePlacesSearch'}
        GooglePlacesSearchQuery={{ rankby: 'distance' }}
        filterReverseGeocodingByTypes={[
          'locality',
          'administrative_area_level_3',
        ]}
        predefinedPlaces={this.createPredefinedPlaces()}
        debounce={200}
        container={<View style={{ flex: 1 }} />}
      />
    </FullScreenHeaderModal>
  );

  getValue = () => {
    let { place } = this.props;
    const { theme } = this.props;
    if (typeof place === 'number') {
      place =
        this.props.placesByID[place] ||
        this.props.userPlaces.features.find(p => p.id === place);
    }
    if (!place)
      return (
        <Text
          style={[
            styles.opaque,
            {
              fontSize: 17,
              color: theme ? getThemeStyles(theme).color : 'black',
            },
          ]}
        >
          Hinzufügen
        </Text>
      );
    const { properties } = place;
    let text;
    if (properties.formatted_address) {
      text = properties.formatted_address;
    }
    if (properties.description) {
      text = properties.description;
    }
    if (properties.address) {
      text = properties.address;
    }
    return <BoldCellValue theme={theme} text={text} />;
  };

  render = () => {
    return (
      <Cell
        title={'Ort'}
        RightComponent={this.getValue()}
        ExpansionComponent={this.renderModal()}
        {...this.props}
        onPress={() => !this.props.isExpanded && this.props.toggleCell('place')}
      />
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlacePicker);
