import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { COLORS } from '../../settings/colors';

const styles = StyleSheet.create({
  markerStyle: {
    height: 30,
    width: 30,
    borderRadius: 15,
    backgroundColor: COLORS.berry,
    shadowOffset: { width: 0, height: 0 },
  },
  value: { fontSize: 25 },
  valueContainer: {
    position: 'absolute',
    top: -50,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 30,
  },
  captionContainer: {
    position: 'absolute',
    bottom: -30,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 30,
  },
  caption: { opacity: 0.5 },
});

function SliderMarker(props) {
  return (
    <View>
      <View style={styles.markerStyle} />
      <View style={styles.valueContainer}>
        <Text style={styles.value}>
          {props.customValue || props.currentValue}
        </Text>
      </View>
      <View style={styles.captionContainer}>
        <Text style={styles.caption}>{props.caption}</Text>
      </View>
    </View>
  );
}

export default SliderMarker;
