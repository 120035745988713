import React from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import DefaultAvatar from '../../assets/images/avatars/default_avatar.png';

const styles = StyleSheet.create({
  number: {
    fontSize: 13,
    fontWeight: 'bold',
    color: 'black',
    backgroundColor: 'white',
  },
  initials: {
    color: 'rgba(0,0,0,0.8)',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  shadow: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 5,
    shadowOpacity: 0.4,
    elevation: 3,
    overflow: 'visible',
  },
});

export default class Avatar extends React.Component {
  getContainerStyle = () => ({
    width: this.props.size,
    height: this.props.size,
    borderRadius: this.props.size / 2,
    justifyContent: 'center',
    alignItems: 'center',
  });

  getBorder = () => {
    if (this.props.border) {
      return {
        borderWidth: this.props.size / 40,
        borderColor: 'white',
      };
    }
    return null;
  };

  getImageStyle = (size, border) => ({
    width: size,
    height: size,
    borderRadius: size / 2,
    borderWidth: border ? size / 40 : 0,
    borderColor: 'white',
  });

  getContent = () => {
    const { size, border, initials } = this.props;
    const { source } = this.props;
    if (source) {
      if (typeof source === 'number') {
        return (
          <Image
            source={DefaultAvatar}
            style={this.getImageStyle(size, border)}
          />
        );
      }
      if (source.uri) {
        return (
          <Image source={source} style={this.getImageStyle(size, border)} />
        );
      }
      return <Image source={source} style={this.getImageStyle(size, border)} />;
    }
    if (initials) {
      return (
        <View
          style={[
            this.getImageStyle(size, border),
            {
              backgroundColor: 'rgba(0,0,0,0.1)',
              justifyContent: 'center',
            },
          ]}
        >
          <Text style={styles.initials}>{initials.toUpperCase()}</Text>
        </View>
      );
    }
    return (
      <Image source={DefaultAvatar} style={this.getImageStyle(size, border)} />
    );
  };

  render = () => {
    return (
      <View
        style={[this.getContainerStyle(), this.props.shadow && styles.shadow]}
      >
        {this.getContent()}
      </View>
    );
  };
}
