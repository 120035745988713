import React from 'react';
import { Text, View, StyleSheet, StatusBar, Platform } from 'react-native';
import { SafeAreaView } from 'react-navigation';
import Modal from 'react-native-modal';
import { HITSLOPS } from '../../settings/UISettings';
import Button from '../UI/Button';
import { COLORS } from '../../settings/colors';
import { isRowLayout } from '../../utilities/screen';

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    fontWeight: '500',
    textAlign: 'center',
    padding: 10,
  },
  button: { padding: 10 },
});

const FullScreenModalWithBottomAction = props => (
  <Modal
    {...props}
    style={!isRowLayout && { margin: 0, ...StyleSheet.absoluteFill }}
    isVisible={props.isVisible}
    hideModalContentWhileAnimating
    useNativeDriver={Platform.OS !== 'web'}
    avoidKeyboard
    onBackdropPress={props.onClose}
  >
    <StatusBar hidden={!props.isVisible} />
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: 'white',
        justifyContent: 'space-between',
        borderRadius: 10,
      }}
    >
      {props.title && (
        <View style={{ flex: 0, flexBasis: Platform.OS === 'web' && 'auto' }}>
          <Text style={styles.title}>{props.title}</Text>
        </View>
      )}
      <View style={{ flex: 1, justifyContent: 'center' }}>
        {props.children}
      </View>
      <Button
        text={'Fertig'}
        backgroundColor={COLORS.lightgray}
        color={COLORS.berry}
        action={props.onClose}
        hitSlop={HITSLOPS.fifteen}
        size={'large'}
        style={styles.button}
      />
    </SafeAreaView>
  </Modal>
);

export default FullScreenModalWithBottomAction;
