import React from 'react';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';
import { COLORS } from '../../settings/colors';

const styles = StyleSheet.create({
  text: {
    color: COLORS.berry,
  },
});

export default function Link(props) {
  return (
    <TouchableOpacity onPress={props.onPress}>
      <Text style={[styles.text]}>{props.children}</Text>
    </TouchableOpacity>
  );
}
