import React from 'react';
import { TouchableOpacity, StyleSheet, Text } from 'react-native';
import { COLORS } from '../../settings/colors';

const styles = StyleSheet.create({
  container: {
    padding: 10,
    paddingHorizontal: 15,
    margin: 5,
    borderRadius: 20,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function TabButton(props) {
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[
        styles.container,
        { backgroundColor: props.isActive ? COLORS.berry : COLORS.lightgray },
      ]}
    >
      <Text
        style={{
          fontSize: 17,
          textAlign: 'center',
          color: props.isActive ? 'white' : 'black',
        }}
      >
        {props.title}
      </Text>
    </TouchableOpacity>
  );
}

export default TabButton;
