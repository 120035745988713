import { createSelector } from 'reselect';
import { getUser } from './user';

export const getParticipation = (state, props) =>
  state.entities.participations.byID[props.participationID];

export const getParticipationsByID = state =>
  state.entities.participations.byID;

export const selectParticipations = createSelector(
  getParticipationsByID,
  participationsByID => Object.values(participationsByID),
);

export const selectUserParticipations = createSelector(
  [selectParticipations, getUser],
  (participations, user) => participations.filter(p => p.user === user.id),
);

// The following selectors are per user. The "user" in the function name
// is omitted to improve readability.

export const selectRequestedParticipations = createSelector(
  selectUserParticipations,
  userParticipations =>
    userParticipations.filter(p => p.status === 'requested'),
);

export const selectConfirmedParticipations = createSelector(
  selectUserParticipations,
  userParticipations =>
    userParticipations.filter(p => p.status === 'confirmed'),
);

export const selectRequestedAndConfirmedParticipations = createSelector(
  selectUserParticipations,
  userParticipations =>
    userParticipations.filter(p => p.status.match(/confirmed|requested/)),
);
