import React from 'react';
import {
  View,
  StyleSheet,
  ImageBackground,
  Platform,
  Animated,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { getThemeStyles, noise } from '../../settings/UISettings';
import { COLORS } from '../../settings/colors';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLORS.black,
    ...StyleSheet.absoluteFill,
  },
  imageBackground: {
    width: '100%',
    height: '100%',
  },
  imageStyle: {
    resizeMode: 'repeat',
    borderRadius: 15,
  },
});

const AnimatedImageBackground = Animated.createAnimatedComponent(
  ImageBackground,
);

export default class InvitationBackground extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: new Animated.Value(0),
    };
  }

  onLoad = () =>
    Animated.timing(this.state.opacity, {
      toValue: 1,
      useNativeDriver: Platform.OS !== 'web',
    }).start();

  theme = getThemeStyles(this.props.theme);

  render() {
    if (this.props.theme === 'image') {
      if (this.props.source) {
        return (
          <View style={styles.container}>
            <AnimatedImageBackground
              useNativeDriver={Platform.OS !== 'web'}
              source={{ uri: this.props.source }}
              defaultSource={require('../../assets/dark-placeholder.png')}
              style={[
                styles.imageBackground,
                {
                  overflow: 'hidden',
                  opacity: this.state.opacity,
                },
              ]}
              imageStyle={{ resizeMode: 'cover' }}
              onLoad={this.onLoad}
            >
              {this.props.type === 'template' ? (
                <View
                  style={{
                    flex: 1,
                    backgroundColor: 'rgba(0,0,0,0.0)',
                  }}
                />
              ) : (
                <LinearGradient
                  colors={[
                    'rgba(0, 0, 0, 0.7)',
                    'rgba(0, 0, 0, 0.0)',
                    'rgba(0, 0, 0, 0.0)',
                    'rgba(0, 0, 0, 0.0)',
                    'rgba(0, 0, 0, 0.7)',
                  ]}
                  style={{
                    flex: 1,
                    justifyContent: 'flex-start',
                  }}
                />
              )}
            </AnimatedImageBackground>
          </View>
        );
      }
    }
    return (
      <LinearGradient
        colors={this.theme.backgroundGradient}
        style={styles.container}
      >
        <ImageBackground
          source={Platform.OS === 'ios' ? noise : null}
          style={[styles.imageBackground, { justifyContent: 'space-around' }]}
          imageStyle={styles.imageStyle}
        />
      </LinearGradient>
    );
  }
}
