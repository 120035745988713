import {
  SET_INVITATION,
  RESET_INVITATION,
  EDIT_INVITATION,
} from '../actions/actionTypes';
import { getNextQuarterHour } from '../../utilities/time';

const initialState = {
  checklist: null,
  contribution_type: 'none',
  starts_at: getNextQuarterHour(),
  ends_at: null,
  groups: [],
  image: null,
  category: null,
  invite_friends: true,
  instant_booking: true,
  min_age: 0,
  max_age: 80,
  // DEPRECATED: kids_welcome: false,
  // labels: null,
  max_participants: null,
  min_participants: null,
  place: null,
  price: null,
  text: [''],
  theme: 'chalkboard',
  visibility: 'public',
  invitees: [],
  is_deleted: false,
  is_closed: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVITATION:
      return {
        ...state,
        ...action.invitation,
      };
    case RESET_INVITATION:
      return {
        ...state,
        text: [''],
        theme: 'paper',
        image: null,
        starts_at: getNextQuarterHour(),
        invitees: [],
        groups: [],
        id: null,
        is_draft: false,
      };
    case EDIT_INVITATION:
      return {
        ...state,
        ...action.invitation,
      };
    default:
      return state;
  }
};

export default reducer;
