import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  Platform,
} from 'react-native';
import { Brandcolor, HITSLOPS } from '../../settings/UISettings';

const styles = StyleSheet.create({
  background: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontWeight: '500',
    textAlign: 'center',
  },
});

export default class Button extends React.Component {
  getSizeStyles = () => {
    let height;
    let fontSize;
    switch (this.props.size) {
      case 'small':
        height = 28;
        fontSize = 15;
        break;
      case 'medium':
        height = 36;
        fontSize = 17;
        break;
      case 'large':
        height = 50;
        fontSize = 20;
        break;
      default:
        height = 28;
        fontSize = 15;
        break;
    }
    return {
      background: {
        borderRadius: height / 2,
        height,
        paddingHorizontal: height / 2,
      },
      text: {
        fontSize,
      },
    };
  };

  getTypeStyles = () => {
    let backgroundColor;
    let textColor;

    switch (this.props.type) {
      case 'primary':
        backgroundColor = Brandcolor;
        textColor = 'white';
        break;
      case 'secondary':
        backgroundColor = '#83306220';
        textColor = Brandcolor;
        break;
      case 'secondary-dark':
        backgroundColor = 'rgba(255, 255, 255, 0.5)';
        textColor = 'rgba(0,0,0,0.7)';
        break;
      case 'secondary-white':
        backgroundColor = 'rgba(0, 0, 0, 0.3)';
        textColor = 'rgba(255,255,255,1)';
        break;
      case 'warning':
        backgroundColor = 'red';
        textColor = 'white';
        break;
      case 'white':
        backgroundColor = 'white';
        textColor = Brandcolor;
        break;
      case 'disabled':
        backgroundColor = 'grey';
        textColor = 'white';
        break;
      default:
        backgroundColor = Brandcolor;
        textColor = 'white';
        break;
    }
    return {
      background: {
        backgroundColor,
      },
      text: {
        color: textColor,
      },
    };
  };

  getTintText = tint =>
    tint === 'dark' ? { color: 'white' } : { color: 'rgba(0,0,0,0.7)' };

  getTintBackground = tint =>
    tint === 'dark'
      ? { backgroundColor: 'rgba(0,0,0,0.7)' }
      : { backgroundColor: 'rgba(0,0,0,0.2)' };

  render = () => {
    return (
      <TouchableOpacity
        onPress={this.props.action || this.props.onPress}
        style={[
          this.props.style,
          this.props.plain && { width: undefined, alignItems: 'flex-start' },
        ]}
        hitSlop={Platform.OS !== 'web' && HITSLOPS.fifteen}
        disabled={this.props.disabled || this.props.isLoading}
      >
        <View
          style={[
            styles.background,
            this.getSizeStyles().background,
            this.props.tint && this.getTintBackground(this.props.tint),
            {
              backgroundColor: this.props.backgroundColor || Brandcolor,
              borderColor: this.props.border || null,
              borderWidth: this.props.border ? 1 : 0,
            },
            this.props.type && this.getTypeStyles().background,
            this.props.disabled && { opacity: 0.3 },
            this.props.plain && {
              backgroundColor: null,
              width: undefined,
              alignItems: 'flex-start',
              paddingHorizontal: 0,
            },
          ]}
        >
          {this.props.isLoading ? (
            <ActivityIndicator color="white" size="small" />
          ) : (
            <Text
              style={[
                styles.text,
                this.getSizeStyles().text,
                this.props.tint && this.getTintText(this.props.tint),
                { color: this.props.color || 'white' },
                this.props.type && this.getTypeStyles().text,
                this.props.plain && { textAlign: 'left' },
              ]}
            >
              {this.props.text}
            </Text>
          )}
        </View>
      </TouchableOpacity>
    );
  };
}

Button.defaultProps = {
  size: 'medium',
};
