import union from 'lodash/union';
import merge from 'lodash/merge';
import {
  UPDATE_ENTITIES,
  UPDATE_IDS,
  ADD_CHECKLIST,
} from '../../actions/actionTypes';

function addChecklist(state = {}, action) {
  return {
    ...state,
    [action.event]: {
      ...state[action.event],
      checklist: action.checklist,
    },
  };
}

function eventsByID(state = {}, action) {
  switch (action.type) {
    default:
      if (action.entities && action.entities.events) {
        return merge({}, state, action.entities.events);
      }
      return state;
  }
}

function allEventIDs(state = [], action) {
  switch (action.type) {
    default:
      if (action.entity === 'events') {
        return union(state, action.result);
      }
      return state;
  }
}

export default function events(
  state = {
    byID: {},
    allIDs: [],
  },
  action,
) {
  switch (action.type) {
    case UPDATE_ENTITIES:
      return {
        ...state,
        byID: eventsByID(state.byID, action),
      };
    case UPDATE_IDS:
      return {
        ...state,
        allIDs: allEventIDs(state.allIDs, action),
      };
    case ADD_CHECKLIST:
      return {
        ...state,
        byID: addChecklist(state.byID, action),
      };
    default:
      return state;
  }
}
