import WebStorage from '@react-native-community/async-storage-backend-web';
import AsyncStorageFactory from '@react-native-community/async-storage';

const webStorage = new WebStorage(); // Default uses LocalStorage

const storage = AsyncStorageFactory.create(webStorage, {
  logger: false,
});

export default storage;
