import union from 'lodash/union';
import merge from 'lodash/merge';
import { UPDATE_ENTITIES, UPDATE_IDS } from '../../actions/actionTypes';

function notificationsByID(state = {}, action) {
  switch (action.type) {
    default:
      if (action.entities && action.entities.notifications) {
        return merge({}, state, action.entities.notifications);
      }
      return state;
  }
}

function allNotificationIDs(state = [], action) {
  switch (action.type) {
    default:
      if (action.entity === 'notifications') {
        return union(state, action.result);
      }
      return state;
  }
}

export default function notifications(
  state = {
    byID: {},
    allIDs: [],
  },
  action,
) {
  switch (action.type) {
    case UPDATE_ENTITIES:
      return {
        ...state,
        byID: notificationsByID(state.byID, action),
      };
    case UPDATE_IDS:
      return {
        ...state,
        allIDs: allNotificationIDs(state.allIDs, action),
      };
    default:
      return state;
  }
}
