// export const env = 'live'; // set to 'local' for local development and 'server' for interaction with the aws cloud

// env.NODE_ENV === 'local';

const environment = process.env ? process.env.NODE_ENV : undefined;

let host;
const ip = 'localhost';
let GOOGLE_MAPS_API_KEY;
let wsHost;

if (environment === 'development') {
  host = `http://${ip}:80`;
  wsHost = `ws://${ip}:80`;
  GOOGLE_MAPS_API_KEY = 'AIzaSyCb_0SuDla4m4ajDX1VJl6_nxOlFuBIiHw';
} else if (environment === 'staging') {
  host = 'https://www.staging.triff.app';
  wsHost = 'wss://www.staging.triff.app';
  GOOGLE_MAPS_API_KEY = 'AIzaSyCb_0SuDla4m4ajDX1VJl6_nxOlFuBIiHw';
} else {
  host = 'https://www.triff.app';
  wsHost = 'wss://www.triff.app';
  GOOGLE_MAPS_API_KEY = 'AIzaSyAUIYfKrUT4xIDwcIYMz7CieRm8A-4dY1o';
}

export { host, wsHost, GOOGLE_MAPS_API_KEY };
