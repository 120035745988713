import { combineReducers } from 'redux';
import omit from 'lodash/omit';
import {
  UI_START_LOADING,
  UI_STOP_LOADING,
  START_LOADING,
  STOP_LOADING,
  SET_NETWORK_DATA,
  SHOW_INFO_MESSAGE,
  HIDE_INFO_MESSAGE,
  SET_NEW_MESSAGE,
  CLEAR_NEW_MESSAGE,
  SET_LAST_DATA_UPDATE,
} from '../actions/actionTypes';
import events from './events';
import notifications from './notifications';
import invitation from './invitation';

const infoMessage = (
  state = {
    isVisible: false,
  },
  action,
) => {
  switch (action.type) {
    case SHOW_INFO_MESSAGE:
      return {
        ...state,
        ...action.messageData,
        isVisible: true,
      };
    case HIDE_INFO_MESSAGE:
      return {
        ...state,
        isVisible: false,
      };
    default:
      return state;
  }
};

const network = (state = {}, action) => {
  switch (action.type) {
    case SET_NETWORK_DATA:
      return {
        ...state,
        ...action.networkData,
      };
    default:
      return state;
  }
};

const loading = (
  state = {
    isLoading: false,
    screens: {},
  },
  action,
) => {
  switch (action.type) {
    case UI_START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case UI_STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case START_LOADING:
      return {
        ...state,
        screens: {
          ...state.screens,
          [action.screen]: true,
        },
      };
    case STOP_LOADING:
      return {
        ...state,
        screens: {
          ...state.screens,
          [action.screen]: false,
        },
      };
    default:
      return state;
  }
};

const newMessage = (
  state = {
    text: '',
    image: {},
    video: {},
  },
  action,
) => ({
  ...state,
  text: Object.prototype.hasOwnProperty.call(action, 'text')
    ? action.text
    : state.text,
  video: Object.prototype.hasOwnProperty.call(action, 'video')
    ? action.video
    : state.video,
  image: Object.prototype.hasOwnProperty.call(action, 'image')
    ? action.image
    : state.image,
});

const newMessages = (state = {}, action) => {
  switch (action.type) {
    case SET_NEW_MESSAGE:
      return {
        ...state,
        [action.conversation]: newMessage(state[action.conversation], action),
      };
    case CLEAR_NEW_MESSAGE:
      return omit(state, action.conversation);
    default:
      return state;
  }
};

const data = (state = { lastUpdate: null }, action) => {
  switch (action.type) {
    case SET_LAST_DATA_UPDATE:
      return {
        ...state,
        lastUpdate: new Date(),
      };
    default:
      return state;
  }
};

const ui = combineReducers({
  events,
  notifications,
  invitation,
  loading,
  network,
  infoMessage,
  newMessages,
  data,
});

export default ui;
