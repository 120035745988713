import {
  GET_WALLET_BALANCE,
  UPDATE_USER_INFO,
  DELETE_CREDIT_CARD,
  UPDATE_SETTINGS,
  UPDATE_NOTIFICATIONS,
  UPDATE_TRIFF_ID,
  STORE_PHONECONTACTS,
  STORE_TRIFF_FRIENDS,
  UPDATE_TRIFF_ADDRESS_BOOK,
  GET_GROUPS,
} from '../actions/actionTypes';

const initialState = {
  id: null,
  email: null,
  first_name: null,
  last_name: null,
  about_me: null,
  username: null,
  phone: null,
  birthday: null,
  iban: null,
  street: null,
  zip: null,
  image: null,
  home_image: null,
  wallet_balance: null,
  currency: null,
  stripe_customer_id: null, // TODO: check if necessary on front end
  card_brand: null,
  card_exp_month: null,
  card_exp_year: null,
  card_last4: null,
  triff_id: null,
  phoneContacts: [],
  triffFriends: [],
  triffAddressbook: [],
  groups: [],
  settings: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WALLET_BALANCE:
      return {
        ...state,
        wallet_balance: action.balance,
        currency: action.currency,
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        ...action.userInfo,
      };
    case DELETE_CREDIT_CARD:
      return {
        ...state,
        card_brand: '',
        card_exp_month: null,
        card_exp_year: null,
        card_last4: null,
      };
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notification_settings: action.notification_settings,
      };
    case UPDATE_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };
    case UPDATE_TRIFF_ID:
      return {
        ...state,
        triff_id: action.triff_id,
      };
    case STORE_PHONECONTACTS:
      return {
        ...state,
        phoneContacts: action.phoneContacts,
      };
    case STORE_TRIFF_FRIENDS:
      return {
        ...state,
        triffFriends: action.triffFriends,
      };
    case UPDATE_TRIFF_ADDRESS_BOOK:
      return {
        ...state,
        triffAddressbook: action.triffAddressbook,
      };
    case GET_GROUPS:
      return {
        ...state,
        groups: action.groups,
      };
    default:
      return state;
  }
};

export default reducer;
