module.exports = {
  apiKey: 'AIzaSyDk9niQuqSslTQGW1T1aYvGHpAksoaXFZs',
  authDomain: 'triff-6b958.firebaseapp.com',
  databaseURL: 'https://triff-6b958.firebaseio.com',
  projectId: 'triff-6b958',
  storageBucket: 'triff-6b958.appspot.com',
  messagingSenderId: '510101735334',
  appId: '1:510101735334:web:21dff3e09afe24fe37b167',
  measurementId: 'G-7JPWRCS28S',
};
