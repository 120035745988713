export const BERRY = 'hsl(324, 51%, 47%)';
export const BEIGE = '#FAF5F5';
export const translucentWhite = 'rgba(255, 255, 255, 0.4)';
export const MINT = '#5ABEB9';
export const SALMON = '#FB8B81';

export const COLORS = {
  berry: BERRY,
  beige: '#FAF5F5',
  black: '#333333',
  mint: '#5ABEB9',
  salmon: '#FB8B81',
  translucentWhite: 'rgba(255, 255, 255, 0.7)',
  lightgray: 'hsl(0, 0%, 94%)',
};
