import * as Sentry from '@sentry/react-native';
import AsyncStorage from '../../../storage';
import { SET_UNREAD_ITEMS, DELETE_UNREAD_ITEMS } from './actionTypes';
import { host } from '../../settings/environment';

function getAuthToken() {
  return AsyncStorage.get('triff:auth:token')
    .catch(err => {
      Sentry.captureException(err);
      Promise.reject(err);
    })
    .then(token => token);
}

export const setUnreadItems = unreadItems => {
  const reducedItems = unreadItems.reduce((object, item) => {
    const type =
      item.content_type === 'guestbook'
        ? item.content_type
        : `${item.content_type}s`;
    // eslint-disable-next-line no-param-reassign
    object[type] = object[type] || [];
    object[type].push(item);
    return object;
  }, {});
  return {
    type: SET_UNREAD_ITEMS,
    unreadItems: reducedItems,
  };
};

export const deleteOnServer = (key, id) => async dispatch => {
  const token = await getAuthToken();
  const model = key === 'guestbook' ? key : key.slice(0, -1);
  const body = { model };
  switch (model) {
    case 'event':
    case 'participation':
      body.event = id;
      break;
    case 'message':
      body.conversation = id;
      break;
    default:
      break;
  }
  if (token) {
    return fetch(`${host}/api/unread-items/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then(
        response => {
          if (!response.ok) {
            throw new Error(response.status);
          }
          return response.json().then(unreadItems => {
            dispatch(setUnreadItems(unreadItems));
          });
        },
        error => {
          throw new Error(error);
        },
      )
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  }
  return null;
};

export const deleteUnreadItems = (key, id = null) => (dispatch, getState) => {
  const hasUnreadItems = getState().ui.notifications[key].length > 0;
  if (!hasUnreadItems) {
    return null;
  }
  dispatch({
    type: DELETE_UNREAD_ITEMS,
    key,
    id,
  });
  return dispatch(deleteOnServer(key, id));
};

export const fetchUnreadItems = () => async dispatch => {
  const token = await getAuthToken();
  if (token) {
    fetch(`${host}/api/unread-items/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    })
      .then(
        response => {
          if (!response.ok) {
            throw new Error(response.status);
          }
          return response.json().then(unreadItems => {
            dispatch(setUnreadItems(unreadItems));
          });
        },
        error => {
          throw new Error(error);
        },
      )
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  }
};
