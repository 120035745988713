import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Brandcolor } from '../../settings/UISettings';
import { COLORS } from '../../settings/colors';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
  },
  containerHorizontal: {
    flex: 0,
    flexBasis: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    paddingBottom: 0,
    marginVertical: 10,
  },
  innerContainerHorizontal: {
    backgroundColor: COLORS.lightgray,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: 50,
    borderRadius: 10,
  },
  buttons: {
    minWidth: 50,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  value: { fontSize: 17, color: 'black' },
  centerContainer: {
    flex: 1,
    height: '100%',
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderColor: 'white',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: 20,
  },
  preOrPostfix: {
    textAlign: 'center',
    fontSize: 20,
    flex: 0,
    flexBasis: 'auto',
  },
});

export default class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interval: null,
      value: this.props.value,
    };
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  addOne(value) {
    if (value % 1 === 0) {
      return value + 1;
    }
    return Math.ceil(value);
  }

  subtractOne(value) {
    if (value % 1 === 0) {
      return value - 1;
    }
    return Math.floor(value);
  }

  increase = () => {
    if (!this.isLimitReached('increase')) {
      this.setState(
        prevState => ({ value: this.addOne(prevState.value) }),
        () => {
          if (this.props.onValueChanged) {
            this.props.onValueChanged(this.state.value);
          }
        },
      );
    }
  };

  decrease = () => {
    if (!this.isLimitReached('decrease')) {
      this.setState(
        prevState => ({ value: this.subtractOne(prevState.value) }),
        () => {
          if (this.props.onValueChanged) {
            this.props.onValueChanged(this.state.value);
          }
        },
      );
    }
  };

  isLimitReached(direction) {
    if (direction === 'increase') {
      if (this.state.value >= this.props.upperLimit) {
        return true;
      }
      return false;
    }
    if (this.state.value <= this.props.lowerLimit) {
      return true;
    }
    return false;
  }

  startFastChange = direction => {
    const interval = setInterval(() => {
      if (!this.isLimitReached(direction)) {
        return this.setState(prevState => ({
          value:
            direction === 'increase'
              ? this.addOne(prevState.value)
              : this.subtractOne(prevState.value),
        }));
      }
      return this.stopFastChange();
    }, 200);
    this.setState({ interval });
  };

  stopFastChange = () => {
    clearInterval(this.state.interval);
    if (this.props.onValueChanged) {
      this.props.onValueChanged(this.state.value);
    }
  };

  getDisplayValue = () => {
    if (this.props.prefix) {
      return `${this.props.prefix} ${this.state.value}`;
    }
    if (this.props.postfix) {
      return `${this.state.value} ${this.props.postfix}`;
    }
    return this.state.value;
  };

  renderPrefix() {
    return (
      <Text
        style={[
          styles.preOrPostfix,
          {
            opacity: this.props.prefix ? 1 : 0,
            lineHeight: this.props.textStyle.fontSize,
            marginHorizontal: 10,
          },
        ]}
      >
        {this.props.prefix}
      </Text>
    );
  }

  renderPostfix() {
    return (
      <Text
        style={[
          styles.preOrPostfix,
          {
            opacity: this.props.postfix ? 1 : 0,
            lineHeight: this.props.textStyle.fontSize,
            marginHorizontal: 10,
          },
        ]}
      >
        {this.props.postfix}
      </Text>
    );
  }

  renderHorizontal() {
    return (
      <View style={styles.containerHorizontal}>
        <View style={styles.innerContainerHorizontal}>
          <Decreaser
            {...this.props}
            onPress={this.decrease}
            onPressIn={() => this.startFastChange('decrease')}
            onPressOut={this.stopFastChange}
            disabled={this.state.value === this.props.lowerLimit}
          >
            <View style={styles.buttons}>
              <Icon
                name={'remove'}
                size={this.props.iconSize || 30}
                color={'black'}
              />
            </View>
          </Decreaser>
          <View style={styles.centerContainer}>
            {this.props.superscript && this.renderPrefix()}
            <Text style={[styles.value, this.props.textStyle]}>
              {this.props.superscript
                ? this.state.value
                : this.getDisplayValue()}
            </Text>
            {this.props.superscript && this.renderPostfix()}
          </View>
          <Increaser
            {...this.props}
            onPress={this.increase}
            onPressIn={() => this.startFastChange('increase')}
            onPressOut={this.stopFastChange}
            disabled={this.state.value === this.props.upperLimit}
          >
            <View style={styles.buttons}>
              <Icon
                name={'add'}
                size={this.props.iconSize || 30}
                color={'black'}
              />
            </View>
          </Increaser>
        </View>
      </View>
    );
  }

  renderVertical() {
    return (
      <View style={styles.container}>
        <Increaser
          {...this.props}
          onPress={this.increase}
          onPressIn={() => this.startFastChange('increase')}
          onPressOut={this.stopFastChange}
          disabled={this.state.value === this.props.upperLimit}
        >
          <Icon
            name={'keyboard_arrow_up'}
            size={this.props.iconSize || 40}
            color={Brandcolor}
          />
        </Increaser>
        <View style={{ paddingVertical: 30 }}>
          <Text style={[{ fontSize: 17 }, this.props.textStyle]}>
            {this.getDisplayValue()}
          </Text>
        </View>
        <Decreaser
          {...this.props}
          onPress={this.decrease}
          onPressIn={() => this.startFastChange('decrease')}
          onPressOut={this.stopFastChange}
          disabled={this.state.value === this.props.lowerLimit}
        >
          <Icon
            name={'keyboard_arrow_down'}
            size={this.props.iconSize || 40}
            color={Brandcolor}
          />
        </Decreaser>
      </View>
    );
  }

  render = () => {
    if (this.props.type === 'vertical') {
      return this.renderVertical();
    }
    return this.renderHorizontal();
  };
}

function Increaser(props) {
  return (
    <TouchableOpacity {...props}>
      {props.disabled ? (
        <View style={{ opacity: 0.4 }}>{props.children}</View>
      ) : (
        <View>{props.children}</View>
      )}
    </TouchableOpacity>
  );
}

function Decreaser(props) {
  return (
    <TouchableOpacity {...props}>
      {props.disabled ? (
        <View style={{ opacity: 0.4 }}>{props.children}</View>
      ) : (
        <View>{props.children}</View>
      )}
    </TouchableOpacity>
  );
}
