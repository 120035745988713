import * as Sentry from '@sentry/react-native';
import dayjs from 'dayjs';
import { normalize } from 'normalizr';
import omit from 'lodash/omit';
import {
  SET_INVITATION,
  RESET_INVITATION,
  EDIT_INVITATION,
} from './actionTypes';
import { getNextQuarterHour, getDuration } from '../../utilities/time';
import { host } from '../../settings/environment';
import { fetchConversation } from './index';
import { updateEntities } from './entities';
import schemata from '../schemata';

export const setInvitation = invitation => {
  let i;
  if (invitation.is_draft) {
    i = { ...invitation };
  } else {
    i = omit(invitation, ['id']);
  }
  // Remove the checklist because we switched to adding the checklist after creating the event:
  i.checklist = null;
  const isPredefined = Object.keys(invitation).length > 1;
  if (isPredefined) {
    if (i.is_draft) {
      if (dayjs().isAfter(dayjs(i.starts_at))) {
        // Update the duration if the draft contained one:
        if (i.ends_at) {
          const duration = getDuration(i.starts_at, i.ends_at);
          i.ends_at = new Date(getNextQuarterHour().valueOf() + duration);
        }
        delete i.starts_at;
      }
    } else {
      // Does the template contain a duration? Use it.
      if (i.ends_at && i.starts_at) {
        const duration = getDuration(i.starts_at, i.ends_at);
        delete i.starts_at;
        i.ends_at = new Date(getNextQuarterHour().valueOf() + duration);
      }
      // Remove start time of template event to later use default start time:
      if (i.starts_at) {
        delete i.starts_at;
      }
    }
  }
  return {
    type: SET_INVITATION,
    invitation: i,
  };
};

export const resetInvitation = () => ({
  type: RESET_INVITATION,
});

export const editInvitation = invitation => {
  const i = { ...invitation };
  if (i.place && i.place.id) {
    i.place = i.place.id;
  }
  return {
    type: EDIT_INVITATION,
    invitation: i,
  };
};

export function postPlace(place) {
  return (dispatch, getState) => {
    return fetch(`${host}/api/places/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${getState().auth.token}`,
      },
      body: JSON.stringify({
        type: 'venue',
        user: getState().account.id,
        location: {
          type: 'Point',
          coordinates: [
            place.geometry.coordinates.lng,
            place.geometry.coordinates.lat,
          ],
        },
        address: place.properties.address,
      }),
    })
      .then(
        response => {
          if (!response.ok) {
            throw new Error(response.status);
          }
          return response.json();
        },
        error => {
          throw new Error(error);
        },
      )
      .then(newPlace => newPlace.id)
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  };
}

export function postInvitation(wasDraft = false) {
  return async (dispatch, getState) => {
    const invitation = { ...getState().ui.invitation };
    const readOnly = [
      // Don't send these attributes.
      'invitees',
      'participations',
      'created_by',
      'admins',
      'hosts',
      'conversation',
      'seen_by',
    ];
    if (wasDraft) {
      invitation.is_draft = false;
    }
    readOnly.forEach(attribute => delete invitation[attribute]);
    invitation.currency = getState().account.currency;
    if (invitation.groups && invitation.groups.length > 0) {
      invitation.groups.forEach((group, index) => {
        if (typeof group === 'object') {
          invitation.groups[index] = group.id;
        }
      });
      invitation.groups = JSON.stringify(invitation.groups);
    } else {
      delete invitation.groups;
    }
    invitation.text = JSON.stringify(invitation.text);
    invitation.starts_at = new Date(invitation.starts_at).toISOString();
    invitation.ends_at = invitation.ends_at
      ? new Date(invitation.ends_at).toISOString()
      : null;
    if (invitation.place && typeof invitation.place !== 'number') {
      invitation.place = invitation.place.isPredefined
        ? invitation.place.id
        : await dispatch(postPlace(invitation.place));
    }
    if (invitation.visibility === 'public') {
      delete invitation.invite_friends; // Public events can be shared by default.
    } else {
      // Visibility should be 'null' if event is not public.
      if (!invitation.visibility) {
        invitation.visibility = 'null'; // 'null' is a valid backend choice.
      }
    }
    if (invitation.contribution_type !== 'price') {
      delete invitation.price;
    }
    if (invitation.image) {
      // TODO: Don't upload image if it wasn't changed.
      invitation.image = {
        uri: invitation.image,
        name: invitation.image.substring(invitation.image.lastIndexOf('/')),
        type: `image/${invitation.image.substring(
          invitation.image.lastIndexOf('.'),
        )}`,
      };
    }
    // New events should not start closed or deleted:
    if (!invitation.id) {
      delete invitation.is_deleted;
      delete invitation.is_closed;
    }
    delete invitation.labels; // Not yet implemented.
    const form = new FormData();
    Object.keys(invitation).forEach(invitationKey => {
      if (invitation[invitationKey]) {
        form.append(invitationKey, invitation[invitationKey]);
      }
    });
    const url = invitation.id
      ? `${host}/api/events/${invitation.id}/`
      : `${host}/api/events/`;
    return fetch(url, {
      method: invitation.id ? 'PUT' : 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Token ${getState().auth.token}`,
      },
      body: form,
    })
      .then(
        response => {
          if (!response.ok) {
            throw new Error(response.status);
          }
          return response.json().then(async event => {
            if (event.conversation) {
              await dispatch(fetchConversation(event.conversation)); // Get new conversation.
            }
            const normalizedData = normalize(event, schemata.event);
            dispatch(updateEntities(normalizedData));
            return event;
          });
        },
        error => {
          throw new Error(error);
        },
      )
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  };
}
