import union from 'lodash/union';
import merge from 'lodash/merge';
import { UPDATE_ENTITIES, UPDATE_IDS } from '../../actions/actionTypes';

function usersByID(state = {}, action) {
  switch (action.type) {
    default:
      if (action.entities && action.entities.users) {
        return merge({}, state, action.entities.users);
      }
      return state;
  }
}

function allUserIDs(state = [], action) {
  switch (action.type) {
    default:
      if (action.entity === 'users') {
        return union(state, action.result);
      }
      return state;
  }
}

export default function users(
  state = {
    byID: {},
    allIDs: [],
  },
  action,
) {
  switch (action.type) {
    case UPDATE_ENTITIES:
      return {
        ...state,
        byID: usersByID(state.byID, action),
      };
    case UPDATE_IDS:
      return {
        ...state,
        allIDs: allUserIDs(state.allIDs, action),
      };
    default:
      return state;
  }
}
