import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import Button from './Button';
import { headline, paragraph } from '../../settings/UISettings';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    flex: 1,
  },
  title: {
    fontSize: headline,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingTop: 15,
  },
  copy: {
    fontSize: paragraph,
    textAlign: 'center',
    paddingTop: 15,
    lineHeight: paragraph * 1.25,
  },
});

export default function InfoView(props) {
  return (
    <View style={[styles.container, props.style]}>
      {props.icon && (
        <Icon name={props.icon} size={100} color={'rgba(0,0,0,0.2)'} />
      )}
      <Text style={styles.title}>{props.title}</Text>
      <Text style={styles.copy}>{props.copy}</Text>
      {props.buttons &&
        props.buttons.map((button, index) => (
          <Button
            key={button.text}
            type={index === 0 ? 'primary' : 'secondary'}
            size={'large'}
            text={button.text}
            action={button.action}
            style={{ paddingTop: 15 }}
          />
        ))}
    </View>
  );
}
