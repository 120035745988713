import React from 'react';
import Color from 'color';
import { Switch, Platform } from 'react-native';
import Cell from './Cell';
import { COLORS } from '../../../settings/colors';
import { getThemeStyles } from '../../../settings/UISettings';

export default function SwitchCell(props) {
  const accentColor = props.theme
    ? getThemeStyles(props.theme).color
    : COLORS.berry;
  const brightBerry = Color(COLORS.berry)
    .lighten(0.1)
    .saturate(0.35);
  return (
    <Cell
      RightComponent={
        <Switch
          trackColor={{
            true:
              Platform.OS === 'ios'
                ? brightBerry
                : Color(accentColor).alpha(0.5),
          }}
          thumbColor={
            Platform.OS === 'android' && props.value ? accentColor : null
          }
          value={props.value}
          onValueChange={props.onValueChange}
        />
      }
      {...props}
    />
  );
}
