import React from 'react';
import { TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Brandcolor } from '../../settings/UISettings';

export default function IconButton(props) {
  return (
    <TouchableOpacity
      style={[
        {
          width: props.size,
          height: props.size,
          borderRadius: props.size,
          backgroundColor: props.backgroundColor || Brandcolor,
          alignItems: 'center',
          justifyContent: 'center',
          opacity: props.disabled ? 0.4 : 1,
        },
        props.style,
      ]}
      onPress={props.onPress}
      disabled={props.disabled}
    >
      <Icon
        name={props.name}
        color={props.color || 'white'}
        size={props.size * 0.6} // Overrides component size prop
      />
    </TouchableOpacity>
  );
}
