import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import { connect } from 'react-redux';
import { Brandcolor } from '../../settings/UISettings';
import Cell from '../UI/Cells/Cell';
import { setInvitation } from '../../store/actions/index';
import { FullScreenModal, FullScreenModalWithBottomAction } from '../UI/Modals';
import { H1, P } from '../UI/TextStyles';
import getThemeStyles from '../../settings/themes';
import TabbedView from '../tabs/TabbedView';
import Stepper from '../UI/Steppers';
import BoldCellValue from '../UI/Cells/BoldCellValue';

const mapDispatchToProps = dispatch => ({
  setInvitation: invitation => dispatch(setInvitation(invitation)),
});

const mapStateToProps = state => ({
  contribution_type: state.ui.invitation.contribution_type,
  price: state.ui.invitation.price,
  currency: state.account.currency,
});

const styles = StyleSheet.create({
  explanationText: {
    fontSize: 18,
    textAlign: 'center',
  },
  smallText: {
    fontSize: 15,
    textAlign: 'center',
  },
  link: {
    color: Brandcolor,
    fontSize: 13,
    textAlign: 'center',
    marginTop: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  opaque: {
    opacity: 0.6,
  },
  modalContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: 30,
  },
});

class PricePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showFeeModal: false,
      price: this.props.price || 1,
      contribution_type: this.props.contribution_type,
    };
  }

  roundTo05(price) {
    const rounded_down = Math.floor(price * 10) / 10;
    let diff = parseFloat(price - rounded_down);
    if (diff >= 0.05) {
      diff -= 0.05;
    }
    const rounded_price = price - diff;
    return rounded_price.toFixed(2);
  }

  openModal = () => this.setState({ showFeeModal: true });

  closeModal = () => this.setState({ showFeeModal: false });

  getValue = () => {
    const { contribution_type, currency, price, theme } = this.props;
    if (contribution_type === 'none') {
      return (
        <Text
          style={[
            styles.opaque,
            {
              fontSize: 17,
              color: theme ? getThemeStyles(theme).color : 'black',
            },
          ]}
        >
          Nichts
        </Text>
      );
    }
    let text;
    switch (contribution_type) {
      case 'share_expenses':
        text = 'Kosten teilen';
        break;
      case 'give_what_you_want':
        text = 'Kollekte';
        break;
      default:
        text = `${currency} ${price}`;
    }
    return <BoldCellValue theme={theme} text={text} />;
  };

  renderFeeModal = () => (
    <FullScreenModal
      animationIn={'fadeInUp'}
      animationOut={'fadeOutDown'}
      isVisible={this.state.showFeeModal}
      onClose={this.closeModal}
    >
      <View style={styles.modalContainer}>
        <H1>Was kostet Triff?</H1>
        <P centered>
          Triffs ohne Beitrag sind immer kostenlos.
          {'\n'}
          {'\n'}
          Bei Triffs mit Fixpreis berechnen wir eine Gebühr von 15% pro
          Teilnehmer. Die gleiche Gebühr gilt für allfälliges Trinkgeld.
          {'\n'}
          {'\n'}
          Bei Triffs mit Kollekte beträgt die Gebühr die Hälfte, nämlich 7,5%
          der Kollekte.
          {'\n'}
          {'\n'}
          Die Gebühren stellen sicher, dass wir die App betreiben und
          weiterentwickeln und dir bei Fragen helfen können. Sie werden abzogen,
          bevor deine Einnahmen deinem Guthaben gutgeschrieben werden.
        </P>
      </View>
    </FullScreenModal>
  );

  writeToInvitation = () => {
    const { contribution_type, price } = this.state;
    this.props.setInvitation({
      price,
      contribution_type,
    });
  };

  saveAndClose = () => {
    this.writeToInvitation();
    this.props.toggleCell('contribution_type');
  };

  renderPicker = () => {
    const { currency } = this.props;
    const { price } = this.state;
    return (
      <View style={{ flex: 1 }}>
        <Text style={styles.smallText}>
          Die Teilnehmer bezahlen vor dem Anlass direkt in der App. Deine
          Einnahmen werden nach dem Anlass automatisch deinem Guthaben
          gutgeschrieben.
        </Text>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Stepper
            superscript
            type={'horizontal'}
            textStyle={{ fontSize: 60 }}
            iconSize={60}
            onValueChanged={newPrice => this.setState({ price: newPrice })}
            decreaseDisabled={price === 1}
            lowerLimit={1}
            value={price || 1}
            prefix={currency}
          />
          <TouchableWithoutFeedback onPress={() => this.openModal()}>
            <View>
              <Text style={styles.explanationText}>
                {`Du erhältst ${currency} ${this.roundTo05(
                  price * 0.85,
                )} pro Teilnehmer.`}
              </Text>
              <Text style={styles.link}>Mehr erfahren</Text>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>
    );
  };

  renderExpansion() {
    return (
      <FullScreenModalWithBottomAction
        onClose={this.saveAndClose}
        isVisible={this.props.isExpanded}
        title={'Beitrag'}
      >
        <TabbedView
          tabs={[
            {
              id: 'none',
              title: 'Nichts',
              content: (
                <Text style={styles.explanationText}>
                  Die Teilnehmer werden nicht aufgefordert, einen Beitrag zu
                  leisten.
                </Text>
              ),
              onTabPress: () =>
                this.setState({
                  contribution_type: 'none',
                }),
            },
            {
              id: 'price',
              title: 'Fixpreis',
              content: this.renderPicker(),
              onTabPress: () =>
                this.setState({
                  contribution_type: 'price',
                }),
            },
            {
              id: 'give_what_you_want',
              title: 'Kollekte',
              content: (
                <TouchableWithoutFeedback onPress={() => this.openModal()}>
                  <View>
                    <Text style={styles.explanationText}>
                      Die Teilnehmer wählen nach dem Anlass, wieviel sie dir
                      geben möchten. Sie bezahlen die Kollekte direkt in der
                      App.
                    </Text>
                    <Text style={styles.link}>Dabei fallen Gebühren an.</Text>
                  </View>
                </TouchableWithoutFeedback>
              ),
              onTabPress: () =>
                this.setState({
                  contribution_type: 'give_what_you_want',
                }),
            },
          ]}
          activeTab={this.props.contribution_type}
        />
        {this.renderFeeModal()}
      </FullScreenModalWithBottomAction>
    );
  }

  render = () => {
    return (
      <Cell
        {...this.props}
        title={'Beitrag'}
        RightComponent={this.getValue()}
        ExpansionComponent={this.renderExpansion()}
        isExpanded={Platform.OS === 'ios' ? this.props.isExpanded : true}
        onPress={() =>
          !this.props.isExpanded && this.props.toggleCell('contribution_type')
        }
      />
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricePicker);
