import { combineReducers } from 'redux';
import conversations from './entities/conversations';
import messages from './entities/messages';
import users from './entities/users';
import events from './entities/events';
import notifications from './entities/notifications';
import participations from './entities/participations';
import groups from './entities/groups';
import bookings from './entities/bookings';
import places from './entities/places';
import templates from './entities/templates';
import checklists from './entities/checklists';
import checklistItems from './entities/checklistItems';

export default combineReducers({
  events,
  templates,
  users,
  messages,
  conversations,
  notifications,
  participations,
  groups,
  bookings,
  places,
  checklists,
  checklistItems,
});
