import * as Sentry from '@sentry/react-native';
import { normalize } from 'normalizr';
import {
  UPDATE_USER_INFO,
  GET_WALLET_BALANCE,
  DELETE_CREDIT_CARD,
  UPDATE_NOTIFICATIONS,
  UPDATE_SETTINGS,
  UPDATE_TRIFF_ID,
  STORE_PHONECONTACTS,
  STORE_TRIFF_FRIENDS,
  UPDATE_TRIFF_ADDRESS_BOOK,
  GET_GROUPS,
} from './actionTypes';
import { uiStartLoading, uiStopLoading } from './index';
import { host } from '../../settings/environment';
import schemata from '../schemata';
import { updateEntities } from './entities';

// const PNF = require('google-libphonenumber').PhoneNumberFormat;
// const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export const updateWalletBalance = (balance, currency) => ({
  type: GET_WALLET_BALANCE,
  balance,
  currency,
});

export const getWalletBalance = token => {
  return dispatch => {
    fetch(`${host}/api/getbalance/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then(parsedRes => {
        const { balance, currency } = parsedRes[0];
        dispatch(updateWalletBalance(balance, currency));
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  };
};

export const setGroup = groups => ({
  type: GET_GROUPS,
  groups,
});

export const getGroups = token => {
  return dispatch => {
    fetch(`${host}/api/groups/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then(groups => {
        const normalizedData = normalize(groups, [schemata.group]);
        dispatch(updateEntities(normalizedData));
        return dispatch(setGroup(groups)); // Maybe not needed anymore.
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  };
};

export const updateTriffID = triff => ({
  type: UPDATE_TRIFF_ID,
  triff_id: triff.id,
});

export const getTriffID = token => {
  return dispatch => {
    fetch(`${host}/api/gettriffid/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then(triff => dispatch(updateTriffID(triff)))
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  };
};

export const updateUserInfo = userInfo => ({
  type: UPDATE_USER_INFO,
  userInfo,
});

export const updateUser = token => dispatch =>
  fetch(`${host}/api/userprofile/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .then(async user => {
      const normalizedData = normalize(user, schemata.user);
      dispatch(updateEntities(normalizedData));
      dispatch(updateUserInfo(user));
      await Promise.all([
        dispatch(getWalletBalance(token)),
        dispatch(getTriffID(token)),
      ]);
      return user;
    })
    .catch(error => {
      Sentry.captureException(error);
      console.log(error);
    });

export const removeCreditCard = () => ({ type: DELETE_CREDIT_CARD });

export const deleteCreditCard = token => {
  return dispatch => {
    dispatch(uiStartLoading());
    fetch(`${host}/api/deletecreditcard/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        dispatch(removeCreditCard());
        dispatch(uiStopLoading());
      })
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  };
};

export const updateIBAN = (token, iban) => {
  return dispatch => {
    return fetch(`${host}/api/iban/`, {
      method: 'PATCH',
      body: JSON.stringify({ iban }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then(() => dispatch(updateUser(token)))
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  };
};

export const updateNotifications = notification_settings => ({
  type: UPDATE_NOTIFICATIONS,
  notification_settings,
});

export const updateSettings = settings => ({
  type: UPDATE_SETTINGS,
  settings,
});

export const storePhoneContacts = phoneContacts => ({
  type: STORE_PHONECONTACTS,
  phoneContacts,
});

export const storeTriffFriends = triffFriends => ({
  type: STORE_TRIFF_FRIENDS,
  triffFriends,
});

export const updateContacts = contacts => ({
  type: UPDATE_TRIFF_ADDRESS_BOOK,
  triffAddressbook: contacts,
});

export const createAddressBook = (phoneContacts = null) => {
  return async (dispatch, getState) => {
    if (Platform.OS === 'web') return;
    const LibPhoneNumber = await import('google-libphonenumber');
    const PNF = LibPhoneNumber.default.PhoneNumberFormat;
    const phoneUtil = LibPhoneNumber.default.PhoneNumberUtil.getInstance();
    const { account } = getState();
    const { friends, phone } = account;
    const userNumber = phoneUtil.parse(phone);
    const isoCode = phoneUtil.getRegionCodeForNumber(userNumber);
    if (!phoneContacts) {
      phoneContacts = account.phoneContacts;
    }
    const triffFriends = friends.map(
      friend =>
        !friend.is_dummy && {
          ...friend,
          source: 'triff',
        },
    );
    const triffAddressBook = triffFriends;
    phoneContacts.forEach(phoneContact => {
      phoneContact.phoneNumbers.forEach(phoneNumber => {
        if (phoneNumber.number.charAt(0) !== '*') {
          const number = phoneUtil.parse(phoneNumber.number, isoCode);
          const valid = phoneUtil.isValidNumber(number);
          const type = phoneUtil.getNumberType(number);
          const formatted_number = phoneUtil.format(number, PNF.E164);
          if (valid && (type === 1 || type === 2)) {
            // Don't add this contact if it is already a friend on the server:
            const isExistingServerContact = triffFriends.some(
              friend => friend.phone === formatted_number,
            );
            if (!isExistingServerContact) {
              triffAddressBook.push({
                first_name: phoneContact.givenName || '',
                last_name: phoneContact.familyName || '',
                image: phoneContact.thumbnailPath,
                phone: formatted_number,
                id: `p${phoneContact.recordID}`, // Add 'p' to avoid accidental matches on server.
                source: 'phone',
              });
            }
          }
        }
      });
    });
    triffAddressBook.sort((a, b) => {
      if (a.id && !b.id) return -1;
      if (!a.id && b.id) return 1;
      return a.first_name && a.first_name.localeCompare(b.first_name);
    });
    dispatch(updateContacts(triffAddressBook));
    return triffAddressBook;
  };
};

export const postAppVersion = app_version => (dispatch, getState) => {
  const { account } = getState();
  const { token } = getState().auth;
  return fetch(`${host}/api/accounts/${account.id}/`, {
    method: 'PATCH',
    body: JSON.stringify({ app_version }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  })
    .then(
      response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response
          .json()
          .then(updatedUser => dispatch(updateUserInfo(updatedUser)));
      },
      error => {
        throw new Error(error);
      },
    )
    .catch(error => {
      Sentry.captureException(error);
      console.log(error);
    });
};
