import { SHADOWS } from './shadows';
import { Platform } from 'react-native';

export const THEMESTYLES = {
  empty: {
    backgroundGradient: ['white', 'white'],
    iconColor: 'white',
    color: '#00000050',
    backgroundColor: 'black',
    placeholder: 'rgba(255, 255, 255, 0.4)',
    backgroundTranslucent: 'rgba(255, 255, 255, 0.1)',
    statusBar: 'dark-content',
    fontWeight: '700',
    fontSizeFactor: 1,
  },
  image: {
    backgroundGradient: ['black', 'black'],
    iconColor: 'white',
    color: '#FFFFFF',
    backgroundColor: 'black',
    placeholder: 'rgba(255, 255, 255, 0.6)',
    backgroundTranslucent: 'rgba(255, 255, 255, 0.15)',
    statusBar: 'light-content',
    fontWeight: '800',
    fontSizeFactor: 1,
    shadow: SHADOWS.text,
  },
  paper: {
    backgroundGradient: ['#FFFBF5', '#F2EFE9'],
    iconColor: '#00000050',
    color: 'rgba(0,0,0,0.9)',
    backgroundColor: '#FFFCF7',
    placeholder: 'rgba(0, 0, 0, 0.4)',
    backgroundTranslucent: 'rgba(0, 0, 0, 0.05)',
    statusBar: 'dark-content',
    fontFamily:
      Platform.OS === 'web' ? 'Playfair Display' : 'PlayfairDisplay-Bold',
    fontWeight: Platform.OS === 'web' ? '600' : '200',
    fontSizeFactor: 1,
  },
  chalkboard: {
    backgroundGradient: ['#4D4D4D', '#1A1A1A'],
    iconColor: 'rgba(255, 255, 255, 0.5)',
    color: 'white',
    backgroundColor: '#404040',
    placeholder: 'rgba(255, 255, 255, 0.4)',
    backgroundTranslucent: 'rgba(255, 255, 255, 0.1)',
    statusBar: 'light-content',
    fontFamily: 'Nocturnal',
    fontSizeFactor: 1.7,
  },
  desert: {
    color: 'hsl(24, 97%, 8%)',
    backgroundColor: '#BFF4F1',
    backgroundGradient: ['#FEFFAE', '#FEA352'],
    placeholder: 'hsla(24, 97%, 8%, 0.4)',
    backgroundTranslucent: 'rgba(0, 0, 0, 0.1)',
    statusBar: 'dark-content',
    fontWeight: '800',
    fontSizeFactor: 1,
  },
  nightsky: {
    color: 'white',
    backgroundColor: '#180756',
    backgroundGradient: ['#800040', '#09022E'],
    placeholder: 'rgba(255, 255, 255, 0.4)',
    backgroundTranslucent: 'rgba(255, 255, 255, 0.1)',
    statusBar: 'light-content',
    fontWeight: '800',
    fontSizeFactor: 1,
  },
  sea: {
    color: 'white',
    backgroundColor: '#180756',
    backgroundGradient: ['#00AEA4', '#0E2439'],
    placeholder: 'rgba(255, 255, 255, 0.4)',
    backgroundTranslucent: 'rgba(255, 255, 255, 0.1)',
    fontWeight: '800',
    statusBar: 'light-content',
    fontSizeFactor: 1,
  },
};

const getThemeStyles = theme => {
  let themeStyles = THEMESTYLES[theme];
  if (!themeStyles) {
    themeStyles = THEMESTYLES.chalkboard;
  }
  return themeStyles;
};

export default getThemeStyles;
