import React from 'react';
import {
  View,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  Text,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { COLORS } from '../../settings/colors';

const styles = StyleSheet.create({
  searchBar: {
    flex: 0,
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    width: '100%',
  },
  inputContainer: {
    height: 40,
    paddingHorizontal: 10,
    backgroundColor: 'rgba(0,0,0,0.05)',
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  input: {
    padding: 5,
    flex: 1,
  },
  cancel: {
    marginLeft: 10,
  },
  cancelText: {
    color: COLORS.berry,
  },
});

export default class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
    this.textInput = React.createRef();
  }

  onFocus = () => {
    this.setState({ isFocused: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  onBlur = () => {
    this.setState({ isFocused: false });
  };

  onCancel = () => {
    this.textInput.current.blur();
    this.props.onCancel();
  };

  onSubmitEditing = () => {
    this.textInput.current.blur();
    this.props.onSubmitEditing();
  };

  render = () => {
    const { darkBackground, header } = this.props;
    return (
      <View
        style={[
          styles.searchBar,
          {
            paddingTop: header ? 0 : null,
            height: header ? 50 : 60,
          },
        ]}
      >
        <View
          style={[
            styles.inputContainer,
            { backgroundColor: darkBackground ? '#00000040' : '#00000010' },
          ]}
        >
          <Icon
            name={'search'}
            size={24}
            color={darkBackground ? '#FFFFFF50' : '#00000050'}
          />
          <TextInput
            {...this.props}
            ref={this.textInput}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            style={[
              styles.input,
              { color: darkBackground ? 'white' : 'black' },
            ]}
            clearButtonMode={'while-editing'}
            onChangeText={this.props.onChangeText}
            onSubmitEditing={this.onSubmitEditing}
            value={this.props.value}
            placeholderTextColor={darkBackground ? '#FFFFFF50' : '#00000050'}
          />
        </View>
        {(this.state.isFocused || this.props.value.length > 0) && (
          <TouchableOpacity onPress={this.onCancel} style={styles.cancel}>
            <Text
              style={{
                color: darkBackground ? 'white' : COLORS.berry,
              }}
            >
              Abbrechen
            </Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };
}
