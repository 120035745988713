export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function joinAndTruncate(array, length) {
  const joinedString = array.join(', ');
  if (joinedString.length > length - 3) {
    const lastSpace = joinedString.lastIndexOf(' ', length - 3);
    return `${joinedString.slice(0, lastSpace)} ...`;
  }
  return joinedString;
}
