import React from 'react';
import { StyleSheet, Text } from 'react-native';
import MultiSlider from '@ptomasroos/react-native-multi-slider';
import { connect } from 'react-redux';
import { COLORS } from '../../settings/colors';
import Cell from '../UI/Cells/Cell';
import { setInvitation } from '../../store/actions/index';
import { FullScreenModalWithBottomAction } from '../UI/Modals';
import getThemeStyles from '../../settings/themes';
import SliderMarker from '../slider/SliderMarker';
import BoldCellValue from '../UI/Cells/BoldCellValue';

const mapDispatchToProps = dispatch => ({
  setInvitation: invitation => dispatch(setInvitation(invitation)),
});

const mapStateToProps = state => ({
  min_age: state.ui.invitation.min_age,
  max_age: state.ui.invitation.max_age,
});

const styles = StyleSheet.create({
  sliderContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
  },
  markerStyle: {
    height: 30,
    width: 30,
    borderRadius: 15,
    backgroundColor: COLORS.berry,
    shadowOffset: { width: 0, height: 0 },
  },
  pressedMarkerStyle: {
    height: 30,
    width: 30,
    borderRadius: 15,
    backgroundColor: COLORS.berry,
  },
  bold: {
    fontWeight: 'bold',
  },
  opaque: {
    opacity: 0.6,
  },
});

class AgePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ages: [props.min_age || 0, props.max_age || 80],
      sliderWidth: 0,
    };
  }

  setAge = ages =>
    this.props.setInvitation({
      min_age: ages[0],
      max_age: ages[1],
    });

  renderExpansion() {
    const { isExpanded } = this.props;
    return (
      <FullScreenModalWithBottomAction
        isVisible={isExpanded}
        onClose={() => this.props.toggleCell('age')}
        title={'Alter'}
        onLayout={({ nativeEvent }) =>
          this.setState({ sliderWidth: nativeEvent.layout.width - 50 })
        }
      >
        <MultiSlider
          isMarkersSeparated
          values={this.state.ages}
          min={0}
          max={80}
          step={1}
          onValuesChangeStart={this.props.disableScroll}
          onValuesChangeFinish={this.setAge}
          onValuesChange={ages => this.setState({ ages })}
          sliderLength={this.state.sliderWidth}
          containerStyle={styles.sliderContainer}
          selectedStyle={{ backgroundColor: COLORS.berry, height: 3 }}
          unselectedStyle={{ backgroundColor: 'rgba(0,0,0,0.2)', height: 3 }}
          markerStyle={styles.markerStyle}
          pressedMarkerStyle={styles.pressedMarkerStyle}
          customMarkerLeft={props => (
            <SliderMarker {...props} caption={'min'} />
          )}
          customMarkerRight={props => (
            <SliderMarker
              {...props}
              caption={'max'}
              customValue={props.currentValue === 80 ? '∞' : props.currentValue}
            />
          )}
        />
      </FullScreenModalWithBottomAction>
    );
  }

  getValue = () => {
    const { min_age, max_age, theme } = this.props;
    if ((!min_age && !max_age) || (min_age === 0 && max_age === 80)) {
      return (
        <Text
          style={[
            styles.opaque,
            {
              fontSize: 17,
              color: theme ? getThemeStyles(theme).color : 'black',
            },
          ]}
        >
          Unbeschränkt
        </Text>
      );
    }
    let text;
    if (max_age === 80) {
      if (min_age === 80) {
        text = `${max_age}+`;
      } else {
        text = `${min_age}-${max_age}+`;
      }
    } else {
      text = `${min_age}-${max_age}`;
    }
    return <BoldCellValue theme={theme} text={text} />;
  };

  render = () => {
    return (
      <Cell
        title={'Alter'}
        RightComponent={this.getValue()}
        ExpansionComponent={this.renderExpansion()}
        {...this.props}
        onPress={() => !this.props.isExpanded && this.props.toggleCell('age')}
      />
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgePicker);
