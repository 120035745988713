import 'react-native-gesture-handler';
import { AppRegistry } from 'react-native';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import materialIconFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import ionIconFont from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import App from './App';
import configureStore from './src/store/configureStore';
import { hydrate } from 'react-dom';

// Generate required css for icons
const iconFontStyles = `
@font-face {
  src: url(${materialIconFont});
  font-family: MaterialIcons;
}

@font-face {
  src: url(${ionIconFont});
  font-family: Ionicons;
}
`;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);

const { store, persistor } = configureStore();

const RNRedux = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={false} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
};

AppRegistry.registerComponent('Triff', () => RNRedux);

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  // react-snap implementation
  hydrate(<RNRedux />, rootElement);
} else {
  AppRegistry.runApplication('Triff', {
    rootTag: rootElement,
  });
}
