import React from 'react';
import { View, Text } from 'react-native';
import { COLORS } from '../../settings/colors';

function Badge(props) {
  const { size, style, number } = props;
  return (
    <View
      style={[
        {
          height: size || 20,
          borderRadius: size ? size / 2 : 10,
          backgroundColor: COLORS.berry,
          minWidth: size || 20,
          paddingHorizontal: size ? size / 4 : 5,
          alignItems: 'center',
          justifyContent: 'center',
          borderColor: 'white',
          borderWidth: props.border ? 2 : 0,
        },
        style,
      ]}
    >
      <Text
        style={{
          fontSize: size ? size / 2 + 2 : 12,
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        {number}
      </Text>
    </View>
  );
}

export default Badge;
