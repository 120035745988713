/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react-native';
import { host } from '../../settings/environment';

export function logAction(action, data = {}) {
  return (dispatch, getState) => {
    return fetch(`${host}/api/log/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${getState().auth.token}`,
      },
      body: JSON.stringify({
        user: getState().account.id,
        action,
        ...data,
      }),
    })
      .then(
        response => {
          if (!response.ok) {
            console.log(response);

            throw new Error(response.status);
          }
          return response.json();
        },
        error => {
          throw new Error(error);
        },
      )
      .catch(error => {
        Sentry.captureException(error);
        console.log(error);
      });
  };
}
