import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import dayjs from 'dayjs';
import { capitalizeFirstLetter } from '../../utilities/strings';
import { CALENDAR, getLocale } from '../../utilities/i18n';
import { getThemeStyles } from '../../settings/UISettings';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
  },
  badge: {
    flex: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 7.5,
  },
  text: {
    color: 'black',
    fontWeight: '500',
    fontSize: 18,
  },
});

export default class DateBadge extends React.Component {
  locale = getLocale().slice(0, 2);

  getDate() {
    const { startsAt, endsAt } = this.props;
    const readableStartsAt = capitalizeFirstLetter(
      dayjs(startsAt).calendar(null, CALENDAR.withTime[this.locale]),
    );
    if (endsAt) {
      const readableEndsAt = capitalizeFirstLetter(
        dayjs(endsAt).calendar(null, CALENDAR.withTime[this.locale]),
      );
      return `${readableStartsAt} - ${readableEndsAt}`;
    }
    return readableStartsAt;
  }

  render = () => {
    const { theme } = this.props;
    const themeStyles = getThemeStyles(theme);
    const backgroundColor = themeStyles.color;
    const color = themeStyles.backgroundColor;
    return (
      <View style={styles.container}>
        <View style={[styles.badge, { backgroundColor }]}>
          <Text style={[styles.text, { color }]}>{this.getDate()}</Text>
        </View>
      </View>
    );
  };
}
