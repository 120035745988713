import { schema } from 'normalizr';

const user = new schema.Entity('users');

const message = new schema.Entity(
  'messages',
  {
    recipients: [user],
    sender: user,
  },
  {
    idAttribute: 'uuid',
  },
);

const group = new schema.Entity('groups', {
  admins: [user],
  members: [user],
});

user.define({
  groups: [group],
});

const participation = new schema.Entity('participations', {
  user,
});

const place = new schema.Entity('places');

const checklistItem = new schema.Entity('checklistItems', {
  checked_by: user,
});

const checklist = new schema.Entity('checklists', {
  items: [checklistItem],
});

const event = new schema.Entity('events', {
  created_by: user,
  admins: [user],
  hosts: [user],
  groups: [group],
  participations: [participation],
  checklist,
  place,
});

const template = new schema.Entity(
  'templates',
  {},
  {
    idAttribute: 'idProxy',
  },
);

const conversation = new schema.Entity('conversations', {
  messages: [message],
  participants: [user],
  event,
});

const booking = new schema.Entity('bookings', {
  user,
});

const schemata = {
  conversation,
  event,
  message,
  user,
  template,
  participation,
  booking,
  group,
  checklist,
  checklistItem,
};

export default schemata;
