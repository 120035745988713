export const getLocale = () => navigator.language;

export const CALENDAR = {
  withTime: {
    en: {
      lastWeek: '[Last] dddd, H:mm',
      lastDay: '[Yesterday], H:mm',
      sameDay: '[Today], H:mm',
      nextDay: '[Tomorrow], H:mm',
      nextWeek: 'dd, MMM D, H:mm',
      sameElse: 'dd, MMM D YYYY, H:mm',
    },
    de: {
      lastWeek: '[Letzten] dddd, H:mm',
      lastDay: '[Gestern], H:mm',
      sameDay: '[Heute], H:mm',
      nextDay: '[Morgen], H:mm',
      nextWeek: 'dd, D. MMM, H:mm',
      sameElse: 'dd, D. MMM YYYY, H:mm',
    },
  },
  withoutTime: {
    en: {
      lastWeek: '[Last] dddd',
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'dd, MMM D',
      sameElse: 'dd, MMM D YYYY',
    },
    de: {
      lastWeek: '[Letzten] dddd',
      lastDay: '[Gestern]',
      sameDay: '[Heute]',
      nextDay: '[Morgen]',
      nextWeek: 'dd, D. MMM',
      sameElse: 'dd, D. MMM YYYY',
    },
  },
  mixed: {
    en: {
      lastWeek: '[Last] dddd',
      lastDay: '[Yesterday]',
      sameDay: 'H:mm',
      nextDay: '[Tomorrow]',
      nextWeek: 'dd, MMM D',
      sameElse: 'dd, MMM D YYYY',
    },
    de: {
      lastWeek: '[Letzten] dddd',
      lastDay: '[Gestern]',
      sameDay: 'H:mm',
      nextDay: '[Morgen]',
      nextWeek: 'dd, D. MMM',
      sameElse: 'dd, D. MMM YYYY',
    },
  },
};
