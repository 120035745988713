import { SET_UNREAD_ITEMS, DELETE_UNREAD_ITEMS } from '../actions/actionTypes';

const initialState = {
  messages: [],
  events: [],
  participations: [],
  relationships: [],
  groups: [],
  guestbook: [],
};

const deleteUnreadItems = (state, action) => {
  switch (action.key) {
    case 'participations':
      return state.filter(
        p => p.content_object && p.content_object.event !== action.id,
      );
    case 'events':
      return state.filter(
        p => p.content_object && p.content_object.id !== action.id,
      );
    case 'messages':
      return state.filter(
        p => p.content_object && p.content_object.conversation !== action.id,
      );
    case 'relationships':
      return [];
    case 'groups':
      return [];
    case 'guestbook':
      return [];
    default:
      return state;
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNREAD_ITEMS:
      return {
        ...state,
        ...action.unreadItems,
      };
    case DELETE_UNREAD_ITEMS:
      return {
        ...state,
        [action.key]: deleteUnreadItems(state[action.key], action),
      };
    default:
      return state;
  }
};

export default reducer;
