import flatten from 'lodash/flatten';
import createCachedSelector from 're-reselect';
import { getParticipationsByID } from './participations';
import { getUser } from './user';
import { getUsersByID } from './users';
import { getGroupsByID } from './groups';

export const getEvent = (state, props) =>
  state.entities.events.byID[props.eventID];

const getBookings = state => state.entities.bookings.byID;

export const selectEventParticipations = createCachedSelector(
  [getEvent, getParticipationsByID],
  (event, participations) => {
    if (!event) {
      return [];
    }
    return Object.values(participations).filter(p => p.event === event.id);
  },
)((state, { eventID }) => eventID);

export const selectConfirmedEventParticipations = createCachedSelector(
  [getEvent, getParticipationsByID],
  (event, participations) => {
    if (!event) {
      return [];
    }
    return Object.values(participations).filter(
      p => p.event === event.id && p.status === 'confirmed',
    );
  },
)((state, { eventID }) => eventID);

export const selectParticipants = createCachedSelector(
  [selectEventParticipations, getUsersByID],
  (participations, users) =>
    participations
      .filter(p => p.status === 'confirmed')
      .map(p => users[p.user]),
)((state, { eventID }) => eventID);

export const selectHosts = createCachedSelector(
  [getEvent, getUsersByID],
  (event, users) => {
    if (!event) {
      return [];
    }
    return event.hosts.map(id => users[id]);
  },
)((state, { eventID }) => eventID);

export const selectInvitees = createCachedSelector(
  [getEvent, getUsersByID, getGroupsByID],
  (event, users, groups) => {
    if (!event) {
      return [];
    }
    const allIDs = flatten(
      event.groups.map(groupID => groups[groupID].members),
    );
    return allIDs.map(id => users[id]);
  },
)((state, { eventID }) => eventID);

export const selectUserParticipation = createCachedSelector(
  [selectEventParticipations, getUser],
  (participations, user) => {
    let participation = null;
    // Is there a confirmed participation of this user?
    participation = participations.find(
      p => p.user === user.id && p.status === 'confirmed',
    );
    // If not, is there a requested/cancelled/dismissed participation?
    if (!participation) {
      participation = participations.find(p => p.user === user.id);
    }
    return participation;
  },
)((state, { eventID }) => eventID);

export const selectBookingsForParticipation = createCachedSelector(
  [selectUserParticipation, getBookings],
  (participation, bookings) => {
    if (!participation) {
      return null;
    }
    return Object.values(bookings).filter(
      b =>
        b.user === participation.user &&
        b.transaction === participation.id.toString() &&
        b.status.match(/CAPTURED|INVOICED/),
    );
  },
)((state, { eventID }) => eventID);
