import {
  UI_START_LOADING,
  UI_STOP_LOADING,
  START_LOADING,
  STOP_LOADING,
  SET_NETWORK_DATA,
  SHOW_INFO_MESSAGE,
  HIDE_INFO_MESSAGE,
  SET_NEW_MESSAGE,
  CLEAR_NEW_MESSAGE,
  SET_LAST_DATA_UPDATE,
} from './actionTypes';

export const uiStartLoading = () => ({
  type: UI_START_LOADING,
});

export const uiStopLoading = () => ({
  type: UI_STOP_LOADING,
});

export const startLoading = screen => ({
  type: START_LOADING,
  screen,
});

export const stopLoading = screen => ({
  type: STOP_LOADING,
  screen,
});

export const setNetworkData = networkData => ({
  type: SET_NETWORK_DATA,
  networkData,
});

export const showInfoMessage = messageData => ({
  type: SHOW_INFO_MESSAGE,
  messageData,
});

export const hideInfoMessage = () => ({
  type: HIDE_INFO_MESSAGE,
});

export function setNewMessage(input) {
  return {
    type: SET_NEW_MESSAGE,
    ...input,
  };
}

export function clearNewMessage(conversation) {
  return {
    type: CLEAR_NEW_MESSAGE,
    conversation,
  };
}

export const setLastDataUpdate = () => ({
  type: SET_LAST_DATA_UPDATE,
});
